import { DocumentTextIcon, PencilIcon, PlusIcon } from '@heroicons/react/solid'
import PropTypes from 'prop-types'
import ActivityIndicator from 'react-spinners/BeatLoader'

const Button = ({ appearance, type, text, onClick, disabled, isLoading, className }) => {
  return (
    <div className={'relative ' + className}>
      <button
        onClick={onClick}
        type={type}
        className={getButtonAppearance(appearance)}
        style={{ opacity: disabled && 0.4, cursor: disabled && 'auto' }}
        disabled={isLoading || disabled}
      >
        {getButtonIcon(appearance, isLoading)}
        <span style={{ opacity: isLoading ? 0 : 1 }}>{text}</span>
      </button>
      {
        isLoading && (
          <div
            className='absolute pt-1 flex justify-center w-full h-full items-center rounded-md left-0 top-0'
          >
            <ActivityIndicator color='white' />
          </div>
        )
      }
    </div>
  )
}

const getButtonAppearance = (appearance) => {
  const base = 'inline-flex items-center border border-transparent shadow-sm focus:outline-none focus:ring-2 focus:ring-offset-2 rounded-md '

  switch (appearance) {
    case 'xs':
      return base + 'px-2.5 py-1.5 text-xs font-medium text-white bg-indigo-600 hover:bg-indigo-700 focus:ring-indigo-500'
    case 'sm':
      return base + 'px-3 py-2 text-sm leading-4 font-medium text-white bg-indigo-600 hover:bg-indigo-700 focus:ring-indigo-500'
    case 'base':
      return base + 'px-4 py-2 text-sm font-medium text-white bg-indigo-600 hover:bg-indigo-700 focus:ring-indigo-500'
    case 'lg':
      return base + 'px-4 py-2 text-base font-medium text-white bg-indigo-600 hover:bg-indigo-700 focus:ring-indigo-500'
    case 'xl':
      return base + 'px-6 py-3 text-base font-medium text-white bg-indigo-600 hover:bg-indigo-700 focus:ring-indigo-500'
    case 'save':
      return base + 'px-4 py-2 text-sm font-medium text-white bg-green-600 hover:bg-green-700 focus:ring-green-500'
    case 'delete':
      return base + 'px-4 py-2 text-sm font-medium text-white bg-red-600 hover:bg-red-700 focus:ring-red-500'
    case 'gray':
      return base + 'px-4 py-2 text-sm font-medium text-gray-700 ring-gray-500 bg-gray-200'
    case 'plus':
      return base + 'px-4 py-2 text-sm font-medium text-white bg-indigo-600 hover:bg-indigo-700 focus:ring-indigo-500'
    case 'edit':
      return base + 'px-4 py-2 text-sm font-medium text-gray-700 bg-gray-100 hover:bg-gray-200 focus:ring-gray-700'
    case 'document':
      return base + 'px-4 py-2 text-sm font-medium text-gray-700 bg-white hover:bg-gray-50 focus:ring-gray-700'
    case 'w-full-base':
      return base + 'w-full justify-center px-4 py-2 bg-indigo-600 text-base font-medium text-white hover:bg-indigo-700 focus:ring-indigo-500 sm:col-start-2 sm:text-sm'
    case 'w-full-white':
      return base + 'w-full justify-center border-gray-300 px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:ring-indigo-500 sm:mt-0 sm:col-start-1 sm:text-sm'
    default:
      return undefined
  }
}

const getButtonIcon = (appearance, isLoading) => {
  switch (appearance) {
    case 'plus':
      return <PlusIcon style={{ opacity: isLoading ? 0 : 1 }} className='-ml-1 mr-2 h-5 w-5' aria-hidden='true' />
    case 'edit':
      return <PencilIcon style={{ opacity: isLoading ? 0 : 1 }} className='-ml-1 mr-2 h-5 w-5 text-gray-400' aria-hidden='true' />
    case 'document':
      return <DocumentTextIcon style={{ opacity: isLoading ? 0 : 1 }} className='-ml-1 mr-2 h-5 w-5 text-gray-400' aria-hidden='true' />
    default:
      return undefined
  }
}

Button.propTypes = {
  appearance: PropTypes.string,
  type: PropTypes.string,
  text: PropTypes.string,
  onClick: PropTypes.func,
  disabled: PropTypes.bool,
  isLoading: PropTypes.bool,
  className: PropTypes.string
}

Button.defaultProps = {
  appearance: 'base',
  type: 'button',
  text: '',
  onClick: () => { },
  disabled: false,
  isLoading: false,
  className: ''
}

export default Button
