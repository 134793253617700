import React from 'react'
import PropTypes from 'prop-types'
import AivyIcon from '../assets/images/aivyicon.svg'

const Loader = ({ loadingText }) => {
  return (
    <div className='flex flex-col min-h-screen items-center justify-center bg-gray-100'>
      <img
        className='block m-6 h-16 w-auto animate-bounce'
        src={AivyIcon}
        alt='Aivy Logo'
      />
      <p className='text-center font-medium text-darkBlack'>{loadingText}</p>
    </div>
  )
}

Loader.propTypes = {
  loadingText: PropTypes.string
}

Loader.defaultProps = {
  loadingText: ''
}

export default Loader
