import { notifyBugsnag } from '.'

const setItem = (key, value) => {
  try {
    typeof window !== 'undefined' && window.localStorage.setItem(key, value)
  } catch (err) {
    // if (err.name === 'QuotaExceededError') {
    //  return
    // }

    notifyBugsnag(err)
  }
}

const getItem = (key) => {
  try {
    return typeof window !== 'undefined' && window.localStorage.getItem(key)
  } catch (err) {
    notifyBugsnag(err)
  }
}

const clear = () => {
  const userId = getItem('user-id')
  const bugsnag = getItem('bugsnag')

  try {
    typeof window !== 'undefined' && window.localStorage.clear()
    setItem('user-id', userId) // persist user id
    setItem('bugsnag', bugsnag) // persist bugsnag object
  } catch (err) {
    notifyBugsnag(err)
  }
}

export const localStorage = {
  setItem,
  getItem,
  clear
}
