import { merge, size } from "lodash";
import { getAnalytics, logEvent } from "firebase/analytics";
import Bugsnag from "@bugsnag/js";
import { localStorage } from "./local-storage";

export const BUILD_ENV = process?.env?.REACT_APP_USER_BRANCH || "develop";
export const NODE_ENV = process?.env?.NODE_ENV;

export const AIVY_INVITATION_URL = (invitationId) =>
  `https://webapp.aivy.app?invitation=${invitationId}`;

export const TALENT_ACTIONS = {
  SEND_REMINDER: "SEND_REMINDER",
};

const EXAM_ID = {
  BIG_FIVE: "BIG_FIVE",
  RIASEC_IMAGES: "RIASEC_IMAGES",
  HEROS_JOURNEY: "HEROS_JOURNEY",
  // TRUST: 'TRUST',
  // TROLLEY: 'TROLLEY',
  // FLYINGDUTCH: 'FLYINGDUTCH',
  PATH_FINDER: "PATH_FINDER",
  FLANKER: "FLANKER",
  EMOTIONS_GAME: "EMOTIONS_GAME",
  ANALOGICAL_REASONING: "ANALOGICAL_REASONING",
  TOWER_OF_LONDON: "TOWER_OF_LONDON",
  DIGIT_SPAN: "DIGIT_SPAN",
  NBACK: "NBACK",
};

const dimensionsData = {
  BIG_FIVE_o_score: {
    challengeID: EXAM_ID.BIG_FIVE,
    dimension: "Persönlichkeit",
    property: "Offenheit für Neues",
    scoreData: [{ challengeID: EXAM_ID.BIG_FIVE, score: "o_score" }],
  },
  BIG_FIVE_c_score: {
    challengeID: EXAM_ID.BIG_FIVE,
    dimension: "Persönlichkeit",
    property: "Gewissenhaftigkeit",
    scoreData: [{ challengeID: EXAM_ID.BIG_FIVE, score: "c_score" }],
  },
  BIG_FIVE_e_score: {
    challengeID: EXAM_ID.BIG_FIVE,
    dimension: "Persönlichkeit",
    property: "Extraversion",
    scoreData: [{ challengeID: EXAM_ID.BIG_FIVE, score: "e_score" }],
  },
  BIG_FIVE_a_score: {
    challengeID: EXAM_ID.BIG_FIVE,
    dimension: "Persönlichkeit",
    property: "Verträglichkeit",
    scoreData: [{ challengeID: EXAM_ID.BIG_FIVE, score: "a_score" }],
  },
  BIG_FIVE_n_score: {
    challengeID: EXAM_ID.BIG_FIVE,
    dimension: "Persönlichkeit",
    property: "Emotionskontrolle",
    scoreData: [{ challengeID: EXAM_ID.BIG_FIVE, score: "n_score" }],
  },
  BIG_FIVE_elevation: {
    challengeID: EXAM_ID.BIG_FIVE,
    dimension: "Selbstreflexion",
    property: "Selbstreflektiertheit bzgl. Persönlichkeit",
    scoreData: [{ challengeID: EXAM_ID.BIG_FIVE, score: "elevation" }],
  },
  RIASEC_IMAGES_elevation: {
    challengeID: EXAM_ID.RIASEC_IMAGES,
    dimension: "Selbstreflexion",
    property: "Selbstreflektiertheit bzgl. beruflicher Interessen",
    scoreData: [{ challengeID: EXAM_ID.RIASEC_IMAGES, score: "elevation" }],
  },
  RIASEC_IMAGES_r_score: {
    challengeID: EXAM_ID.RIASEC_IMAGES,
    dimension: "Berufliche Interessen",
    property: "Handwerklich-technisch",
    scoreData: [{ challengeID: EXAM_ID.RIASEC_IMAGES, score: "r_score" }],
  },
  RIASEC_IMAGES_i_score: {
    challengeID: EXAM_ID.RIASEC_IMAGES,
    dimension: "Berufliche Interessen",
    property: "Untersuchend-forschend",
    scoreData: [{ challengeID: EXAM_ID.RIASEC_IMAGES, score: "i_score" }],
  },
  RIASEC_IMAGES_a_score: {
    challengeID: EXAM_ID.RIASEC_IMAGES,
    dimension: "Berufliche Interessen",
    property: "Künstlerisch-kreativ",
    scoreData: [{ challengeID: EXAM_ID.RIASEC_IMAGES, score: "a_score" }],
  },
  RIASEC_IMAGES_s_score: {
    challengeID: EXAM_ID.RIASEC_IMAGES,
    dimension: "Berufliche Interessen",
    property: "Erziehend-pflegend",
    scoreData: [{ challengeID: EXAM_ID.RIASEC_IMAGES, score: "s_score" }],
  },
  RIASEC_IMAGES_e_score: {
    challengeID: EXAM_ID.RIASEC_IMAGES,
    dimension: "Berufliche Interessen",
    property: "Führend-verkaufend",
    scoreData: [{ challengeID: EXAM_ID.RIASEC_IMAGES, score: "e_score" }],
  },
  RIASEC_IMAGES_c_score: {
    challengeID: EXAM_ID.RIASEC_IMAGES,
    dimension: "Berufliche Interessen",
    property: "Ordnend-verwaltend",
    scoreData: [{ challengeID: EXAM_ID.RIASEC_IMAGES, score: "c_score" }],
  },
  HEROS_JOURNEY_score_coopcomp: {
    challengeID: EXAM_ID.HEROS_JOURNEY,
    dimension: "Werteorientierung",
    property: "Fokus auf Unabhängigkeit statt Zusammenarbeit",
    scoreData: [
      { challengeID: EXAM_ID.HEROS_JOURNEY, score: "score_coopcomp" },
    ],
  },
  HEROS_JOURNEY_score_orderchaos: {
    challengeID: EXAM_ID.HEROS_JOURNEY,
    dimension: "Werteorientierung",
    property: "Fokus auf Flexibilität statt Stabilität",
    scoreData: [
      { challengeID: EXAM_ID.HEROS_JOURNEY, score: "score_orderchaos" },
    ],
  },
  // TRUST_score: {
  //  challengeID: EXAM_ID.TRUST,
  //  dimension: 'Werteorientierung',
  //  property: 'Fokus auf Vorteile für Anderer statt sich selbst',
  //  scoreData: [{ challengeID: EXAM_ID.TRUST, score: 'score' }]
  // },
  // TROLLEY_score: {
  //  challengeID: EXAM_ID.TROLLEY,
  //  dimension: 'Werteorientierung',
  //  property: 'Fokus auf Prinzipien statt dem reinen Zweck',
  //  scoreData: [{ challengeID: EXAM_ID.TROLLEY, score: 'score' }]
  // },
  // SPEEDACC_score: {
  //  challengeID: '',
  //  dimension: 'Entscheidungsverhalten',
  //  property: 'Fokus auf Geschwindigkeit statt Genauigkeit',
  //  scoreData: [{ challengeID: '' }]
  // },
  // FLYINGDUTCH_score: {
  //  challengeID: EXAM_ID.FLYINGDUTCH,
  //  dimension: 'Entscheidungsverhalten',
  //  property: 'Risikobereitschaft',
  //  scoreData: [{ challengeID: EXAM_ID.FLYINGDUTCH, score: 'risktolerance' }]
  // },
  // RESPDECISION_score: {
  //  challengeID: EXAM_ID.FLYINGDUTCH,
  //  dimension: 'Entscheidungsverhalten',
  //  property: 'Verantwortungsbereitschaft',
  //  scoreData: [{ challengeID: EXAM_ID.FLYINGDUTCH, score: 'respdecision' }]
  // },
  PATH_FINDER_score: {
    challengeID: EXAM_ID.PATH_FINDER,
    dimension: "Auffassungsgabe",
    property: "Problemlösungsverhalten",
    scoreData: [{ challengeID: EXAM_ID.PATH_FINDER, score: "score" }],
  },
  FLANKER_score: {
    challengeID: EXAM_ID.FLANKER,
    dimension: "Auffassungsgabe",
    property: "Verhaltenskontrolle und selektive Wahrnehmung",
    scoreData: [{ challengeID: EXAM_ID.FLANKER, score: "score" }],
  },
  EMOTIONS_GAME_score: {
    challengeID: EXAM_ID.EMOTIONS_GAME,
    dimension: "Auffassungsgabe",
    property: "Wahrnehmung von Emotionen",
    scoreData: [{ challengeID: EXAM_ID.EMOTIONS_GAME, score: "score" }],
  },
  ANALOGICAL_REASONING_score: {
    challengeID: EXAM_ID.ANALOGICAL_REASONING,
    dimension: "Auffassungsgabe",
    property: "Vernetztes Denken",
    scoreData: [{ challengeID: EXAM_ID.ANALOGICAL_REASONING, score: "score" }],
  },
  TOWER_OF_LONDON_score: {
    challengeID: EXAM_ID.TOWER_OF_LONDON,
    dimension: "Auffassungsgabe",
    property: "Planungsverhalten",
    scoreData: [{ challengeID: EXAM_ID.TOWER_OF_LONDON, score: "score" }],
  },
  DIGIT_SPAN_score: {
    challengeID: EXAM_ID.DIGIT_SPAN,
    dimension: "Auffassungsgabe",
    property: "Kurzfristige Merkfähigkeit",
    scoreData: [{ challengeID: EXAM_ID.DIGIT_SPAN, score: "score" }],
  },
  NBACK_score: {
    challengeID: EXAM_ID.NBACK,
    dimension: "Auffassungsgabe",
    property: "Kurzfristiges Wiedererkennen",
    scoreData: [{ challengeID: "NBACK", score: "score" }],
  },
};

/**
 * calculates the icc for each dimension configuration
 * @param {*} career.career_analyse // queries.summarizeAnalyses
 * @param {*} careerAnalyses // queries.careerAnalyseByCareer
 * @param {*} forced_dimension // queries.summarizeAnalyses
 * @param {*} screen // actual screen
 * @returns the configurations array
 */
export const calculateIccForConfigurations = (
  c,
  a,
  forcedDimension,
  screen
) => {
  const careerAnalyse = JSON.parse(c); // saved as a string in the database
  const careerAnalyses = JSON.parse(JSON.stringify(a)) // deep clone via stringify / parse
    .filter(({ status }) => status === "COMPLETED")
    .map((analyse) => ({ ...analyse, input: JSON.parse(analyse.input) }));

  if (!careerAnalyses.length) return [];

  const removeDimensions = (...dimensions) => {
    dimensions.forEach((dimension) => {
      delete careerAnalyse.scores[dimension];
      careerAnalyses.forEach((analyse) => delete analyse.input[dimension]);
    });
  };

  // @Jasmine - 2021/11/10
  removeDimensions(
    "SPEEDACC_score",
    "TROLLEY_score",
    "TRUST_score",
    "RESPDECISION_score",
    "FLYINGDUTCH_score"
  );

  Object.keys(careerAnalyse.scores).forEach((key) => {
    if (
      !careerAnalyse.scores[key].uncertaintyWeight ||
      careerAnalyse.scores[key].importance <= 0.5
    ) {
      removeDimensions(key);
    }
  });

  const uncertaintyWeightAllDimensions = Object.keys(careerAnalyse.scores)
    .map((key) => [
      careerAnalyse.scores[key].uncertaintyWeight,
      careerAnalyse.scores[key].importance ?? 1,
    ])
    .reduce(
      (sum, [uncertaintyWeight, importance]) =>
        sum + uncertaintyWeight * importance,
      0
    );

  let result = [];
  // min 4 dimensions for min 4 games (duration 10 minutes)
  while (Object.keys(careerAnalyse.scores).length > 3) {
    const dimensions = Object.keys(careerAnalyse.scores).map((key) => ({
      key,
      uncertaintyWeight: careerAnalyse.scores[key].uncertaintyWeight,
      importance: careerAnalyse.scores[key].importance ?? 1,
      forcedDimension: key === forcedDimension || undefined,
    }));

    const uncertaintyWeightActualDimensions = dimensions.reduce(
      (sum, { uncertaintyWeight, importance }) =>
        sum + uncertaintyWeight * importance,
      0
    );

    result.push({
      ...careerAnalyse.scores,
      icc:
        Math.round(calculateIcc(careerAnalyses, careerAnalyse) * 10000) / 100,
      cumulativeWeight:
        Math.round(
          (uncertaintyWeightActualDimensions / uncertaintyWeightAllDimensions) *
            10000
        ) / 100,
    });

    // find dimension with lowest uncertainty weight * importance
    const { key } = dimensions
      .sort((a, b) => {
        const weightingA = a.uncertaintyWeight * a.importance;
        const weightingB = b.uncertaintyWeight * b.importance;

        if (weightingA === weightingB) {
          return b.uncertaintyWeight - a.uncertaintyWeight;
        }

        return weightingB - weightingA;
      })
      .sort(({ forcedDimension }) => (forcedDimension ? -1 : 1))
      .pop();

    // remove dimension
    removeDimensions(key);
  }

  result = mapDimensionsToConfigurations(result, screen);

  // find tradeoff -> where the amount from icc and cumulative weight is the lowest
  const tradeoff = result.sort((a, b) => {
    const absA = Math.abs(a.icc - a.cumulativeWeight);
    const absB = Math.abs(b.icc - b.cumulativeWeight);

    return absA - absB;
  });

  result = result.map((configuration) => ({
    ...configuration,
    tradeoff: tradeoff[0] && configuration.id === tradeoff[0].id,
  }));

  result.sort((a, b) => a.challenges.length - b.challenges.length);

  return result;
};

const mapDimensionsToConfigurations = (result, screen) => {
  const dimensionsToGames = new Map(
    Object.keys(dimensionsData).map((dimension) => [
      dimension,
      dimensionsData[dimension].challengeID,
    ])
  );

  result = result
    .map((configuration) => {
      const dimensions = Object.keys(configuration).filter(
        (key) => key !== "icc" && key !== "cumulativeWeight"
      );

      // create a set for unique challenge entries
      const challenges = [
        ...new Set(
          dimensions.map((dimension) => dimensionsToGames.get(dimension))
        ),
      ];

      return {
        id: uuidSmall(),
        challenges,
        // stringify challenges to remove double configurations
        compareChallenges: JSON.stringify(challenges.sort()),
        dimensions: configuration,
        icc: configuration.icc,
        cumulativeWeight: configuration.cumulativeWeight,
      };
    })
    .sort(
      (a, b) =>
        Object.keys(a.dimensions).length - Object.keys(b.dimensions).length
    )
    .filter(
      // filter configurations with icc < 40 only for WAIT_CONFIG screen
      ({ challenges, icc }) =>
        challenges.length > 3 && (screen === "WAIT_EXPERTS" || icc >= 40)
    )
    .map((configuration) => ({
      ...configuration,
      duration: configuration.challenges.length * 2.5,
    }));

  // remove double configurations
  result = uniqueByKeepLast(result, (it) => it.compareChallenges)
    // remove compare challenges && map challenge array to { exam_id: 'EXAMPLE' }
    .map((configuration) => {
      delete configuration.compareChallenges;
      configuration.challenges = configuration.challenges.map((challenge) => ({
        exam_id: challenge,
      }));

      return configuration;
    });

  const dimensions = Object.keys(dimensionsData).map((key) => ({
    key,
    ...dimensionsData[key],
  }));

  /// all dimensions covered by the challenges
  result = result.map((configuration) => {
    const coveredDimensions = configuration.challenges.map((challenge) =>
      findAllDimensions(dimensions, challenge.exam_id)
    );

    const dimensionsWithUncertaintyWeight = Object.keys(
      configuration.dimensions
    );

    return {
      ...configuration,
      dimensionsCoveredByChallenges: coveredDimensions
        .flat()
        .filter(
          (dimension) => !dimensionsWithUncertaintyWeight.includes(dimension)
        ),
    };
  });

  return result;
};

const findAllDimensions = (dimensions, challenge) => {
  return dimensions
    .filter(({ challengeID }) => challengeID === challenge)
    .map(({ key }) => key);
};

const uniqueByKeepLast = (data, key) => {
  return [...new Map(data.map((element) => [key(element), element])).values()];
};

/**
 * Returns null if no partnerId found
 */
export function getPartnerIdFromLocaleStorage() {
  return localStorage.getItem("partner-id");
}

export function trackEvent(eventName, eventParams) {
  if (BUILD_ENV !== "production") return;

  const partnerId = getPartnerIdFromLocaleStorage() || "NOT_SET";

  const analytics = getAnalytics();
  if (eventParams) {
    logEvent(analytics, eventName, { ...eventParams, partner: partnerId });
    return;
  }
  logEvent(analytics, eventName, { partner: partnerId });
}

/**
 * 10-30min
 *
 * 1. Absteigend sortieren
 * 2. CHECK MINs / 2.5 --> Anzahl Challenges
 * 3. Pick Anzahl Challenges from top of the list
 */
export function calculateInformationContentChallenges(careerAnalyse) {
  const res = {};

  for (const [key] of Object.entries(EXAM_ID)) {
    let sum = 0;
    const mergedObj = merge(dimensionsData, careerAnalyse.scores);

    for (const [keyScore, valueScore] of Object.entries(mergedObj)) {
      // eslint-disable-line
      if (key === valueScore?.scoreData[0]?.challengeID) {
        sum += valueScore.uncertaintyWeight;
      }
    }
    res[key] = sum;
  }

  Object.keys(res).forEach((key) => res[key] === 0 && delete res[key]);

  return res;
}

/**
 * Calculates the ICC from the career analyses
 * @param {*} careerAnalyses Array containing the objects of type CareerAnalyse from specifig career
 * @param {*} career Career Object containing career_analyse with merged of all single career analyses
 * @returns
 */
export function calculateIcc(careerAnalyses, careerAnalyse) {
  // Take the first 4 challenges from challenges with informationContent
  // if < 0.4 take the next challenge and repeat icc
  // Check Qualitätsscore colors incl range 0.4 0.75

  const k = careerAnalyses.length; // Anzahl Rater (Length of Career Analyses)
  const n = size(careerAnalyses[0].input); // Anzahl Dimensionen

  // Berechne Mittelwert pro Rater [Mi]
  const averageInputPerRater = careerAnalyses.map(
    (analyse) =>
      Object.values(analyse.input).reduce((sum, { score }) => sum + score, 0) /
      n
  );

  // Mittelwert über alle Optimals der career_analyse [mean_ges]
  const averageOptimalsCareerAnalyse =
    Object.values(careerAnalyse.scores).reduce(
      (sum, dimension) => sum + dimension?.optimal || sum + 0,
      0
    ) / n;

  // Berechne Mean Square between Subjects (MSBS)
  const QSBS = Object.values(careerAnalyse.scores).reduce((sum, dimension) => {
    if (typeof dimension === "object") {
      return (
        sum + Math.pow(dimension.optimal - averageOptimalsCareerAnalyse, 2)
      );
    }
    return sum + 0;
  }, 0);

  const MSBS = (k * QSBS) / (n - 1);

  // Berechne Mean Square between Measurements (MSBM)
  const QSBM = averageInputPerRater.reduce(
    (sum, number) => sum + Math.pow(number - averageOptimalsCareerAnalyse, 2),
    0
  );
  const MSBM = (n * QSBM) / (k - 1);

  // Berechne Mean Error (MSE)
  let QSE = 0;
  careerAnalyses.forEach((rater, index) => {
    for (const [key, value] of Object.entries(rater.input)) {
      const analyse = careerAnalyse.scores;
      const Sj = analyse[key].optimal;
      const Mi = averageInputPerRater[index];

      const xij = value.score;
      QSE = QSE + Math.pow(xij - Sj - Mi + averageOptimalsCareerAnalyse, 2);
    }
  });

  const MSE = QSE / ((n - 1) * (k - 1));

  if (MSBS + (k - 1) * MSE + ((MSBM - MSE) * k) / n === 0) return 1; // IF NENNER 0
  const ICC = (MSBS - MSE) / (MSBS + (k - 1) * MSE + ((MSBM - MSE) * k) / n);

  return ICC < 0 ? 0 : ICC;
}

export function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

// https://stackoverflow.com/a/2117523
export function uuidv4() {
  return "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(/[xy]/g, function (c) {
    const r = (Math.random() * 16) | 0;
    const v = c === "x" ? r : (r & 0x3) | 0x8; // eslint-disable-line

    return v.toString(16);
  });
}

export function uuidSmall() {
  return "xxxxxxxxxxxx".replace(/[xy]/g, function (c) {
    const r = (Math.random() * 16) | 0;
    const v = c === "x" ? r : (r & 0x3) | 0x8; // eslint-disable-line

    return v.toString(16);
  });
}

const channels = {
  _bugsnag:
    "https://hooks.slack.com/services/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",
  "kunden-controlling":
    "https://hooks.slack.com/services/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",
};

function _sendSlackMessage(channel, message) {
  return window.fetch(channels[channel], {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/x-www-form-urlencoded",
    },
    body: JSON.stringify({ text: message }),
  });
}

export async function sendSlackMessage(channel, message, throwError) {
  // because of cors policy
  if (NODE_ENV === "development") {
    return console.log("send-slack-message:", message);
  }

  try {
    await _sendSlackMessage(channel, message);
  } catch (err) {
    notifyBugsnag(err);

    if (throwError) {
      throw err;
    }
  }
}

export const notifyBugsnag = (error) => {
  console.error(error);

  if (NODE_ENV === "development") return;
  if (error === "The user is not authenticated") return;

  if (
    // graphql error
    error &&
    Array.isArray(error.errors) &&
    error.errors[0]
  ) {
    return Bugsnag.notify(
      new Error(
        `${error.errors[0].errorType} - ${error.errors[0].message} (${
          error.errors[0].path && error.errors[0].path[0]
        }) - graphql error`
      )
    );
  }

  Bugsnag.notify(error);
};
