import React from 'react'
import PropTypes from 'prop-types'
import ActivityIndicator from 'react-spinners/BeatLoader'

import { colors } from '../constants/colors'

const Loader = ({ className }) => (
  <div className={className}>
    <ActivityIndicator color={colors.primaryBlue} />
  </div>
)

Loader.propTypes = {
  className: PropTypes.string
}

Loader.defaultProps = {
  className: 'flex justify-center pt-4'
}

export default Loader
