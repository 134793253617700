import PropTypes from 'prop-types'

const State = ({ color, text, height }) => {
  const getColor = () => {
    switch (color) {
      case 'green':
        return 'bg-green-100 text-green-800'
      case 'grey':
        return 'bg-gray-100 text-gray-800'
      case 'orange':
        return 'bg-yellow-100 text-yellow-800'
      case 'red':
        return 'bg-red-100 text-red-800'
      default:
        return 'bg-gray-100 text-gray-800'
    }
  }

  return (
    <span
      style={{ height }}
      className={'inline-flex items-center px-3 py-0.5 rounded-full text-sm font-medium ' + getColor()}
    >
      {text}
    </span>
  )
}

State.propTypes = {
  color: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired,
  height: PropTypes.number
}

State.defaultProps = {
  height: 24
}

export default State
