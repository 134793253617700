import PropTypes from "prop-types";
import { RadioGroup } from "@headlessui/react";

import { classNames } from "../helpers";
import { useTranslation } from "react-i18next";

const RadioListGroup = ({ group, selected, setSelected }) => {
  const { t } = useTranslation();

  return (
    <RadioGroup value={selected} onChange={setSelected}>
      <div className="bg-white rounded-md -space-y-px md:max-w-2xl">
        {group.map((option, index) => (
          <RadioGroup.Option
            key={index}
            value={option.id}
            className={({ checked, disabled }) =>
              classNames(
                index === 0 ? "rounded-tl-md rounded-tr-md" : "",
                index === group.length - 1 ? "rounded-bl-md rounded-br-md" : "",
                checked
                  ? "bg-indigo-50 border-indigo-200 z-10"
                  : "border-gray-200",
                disabled && "bg-gray-50",
                !disabled && "cursor-pointer",
                "relative border p-4 flex focus:outline-none"
              )
            }
            disabled={!option.available}
          >
            {({ checked, disabled }) => (
              <>
                <span
                  className={classNames(
                    checked
                      ? "bg-indigo-600 border-transparent"
                      : "bg-white border-gray-300",
                    disabled && "bg-gray-50",
                    !disabled && "cursor-pointer",
                    "h-4 w-4 mt-0.5 rounded-full border flex items-center justify-center flex-shrink-0"
                  )}
                  aria-hidden="true"
                >
                  <span
                    className={classNames(
                      "rounded-full bg-white w-1.5 h-1.5",
                      disabled && "bg-gray-50"
                    )}
                  />
                </span>
                <div className="ml-3 flex flex-col">
                  <RadioGroup.Label
                    as="span"
                    className={classNames(
                      checked ? "text-indigo-900" : "text-gray-900",
                      "block text-sm font-medium"
                    )}
                  >
                    {t(option.label)}
                  </RadioGroup.Label>
                  <RadioGroup.Description
                    as="span"
                    className={classNames(
                      checked ? "text-indigo-700" : "text-gray-500",
                      "block text-sm"
                    )}
                  >
                    {t(option.description)}
                  </RadioGroup.Description>
                  {disabled && (
                    <RadioGroup.Description
                      as="p"
                      className="mt-2 ml-auto text-gray-500 block text-xs italic"
                    >
                      {t(option.notAvailable)}
                    </RadioGroup.Description>
                  )}
                </div>
              </>
            )}
          </RadioGroup.Option>
        ))}
      </div>
    </RadioGroup>
  );
};

RadioListGroup.propTypes = {
  group: PropTypes.array.isRequired,
  selected: PropTypes.string.isRequired,
  setSelected: PropTypes.func.isRequired,
};

export default RadioListGroup;
