import React, { Fragment } from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { Disclosure, Menu, Transition } from "@headlessui/react";
import { MenuIcon, XIcon } from "@heroicons/react/outline";
import { Link } from "react-router-dom";
import { Auth, Hub } from "aws-amplify";

import SwitchLang from "./switch-lang";
import AivyIcon from "../assets/images/aivyicon.svg";
import ProfilePicture from "../assets/images/profilepicture.png";
import AivyLogo from "../assets/images/logo-alt.png";
import { notifyBugsnag, trackEvent } from "../helpers";
import { localStorage } from "../helpers/local-storage";

import { Popover } from "@headlessui/react";
import { ChevronDownIcon } from "@heroicons/react/solid";
import {
  ChartBarIcon,
  CursorClickIcon,
  DocumentReportIcon,
  RefreshIcon,
  ShieldCheckIcon,
  ViewGridIcon,
} from "@heroicons/react/outline";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

const isActive = (active, value = "grey") => {
  if (value === "grey") {
    return active ? "bg-gray-100 text-gray-900" : "text-gray-700";
  }

  if (value === "bright-blue") {
    return active
      ? "border-brightBlue text-gray-900"
      : "border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300";
  }

  if (value === "indigo") {
    return active
      ? "bg-indigo-50 border-indigo-500 text-indigo-700"
      : "border-transparent text-gray-600 hover:bg-gray-50 hover:border-gray-300 hover:text-gray-800";
  }
};

const TRANSLATION_LOCATION = "components.profile_dropdown.";

const solutions = [
  {
    name: "Kunden",
    description: "Übersicht aller bezahlenden Kunden",
    href: "/partner",
    icon: ChartBarIcon,
  },
  {
    name: "Leads",
    description: "Übersicht aller Heros",
    href: "/leads",
    icon: CursorClickIcon,
  },
];

const SiteNavigationContainer = ({
  userNavigation,
  generalNavigation,
  cognitoUser,
  setVisibilityChooseGroup,
}) => {
  const { t } = useTranslation();

  return (
    <Disclosure as="nav" className="bg-white shadow-sm">
      {({ open }) => (
        <>
          <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
            <div className="flex justify-between h-16">
              <div className="flex">
                <div className="flex-shrink-0 flex items-center">
                  <Link to="/">
                    <img
                      className="block lg:hidden h-9 w-auto"
                      src={AivyIcon}
                      alt="Workflow"
                    />
                  </Link>
                  <Link to="/">
                    <img
                      className="hidden lg:block h-9 w-auto cursor-pointer"
                      src={AivyLogo}
                      alt="Workflow"
                    />
                  </Link>
                </div>
                <div className="hidden sm:-my-px sm:ml-6 sm:flex sm:space-x-8">
                  {generalNavigation.map((item) => (
                    <Link
                      key={item.name}
                      to={item.href}
                      className={classNames(
                        isActive(item.current, "bright-blue"),
                        "inline-flex items-center px-1 pt-1 border-b-2 text-sm font-medium"
                      )}
                      aria-current={item.current ? "page" : undefined}
                    >
                      {item.name}
                    </Link>
                  ))}
                </div>
              </div>
              <div className="hidden sm:ml-6 sm:flex sm:items-center">
                <Popover className="relative mr-10">
                  {({ open }) => (
                    <>
                      <Popover.Button
                        className={classNames(
                          open ? "text-gray-900" : "text-gray-500",
                          "group bg-white rounded-md inline-flex items-center text-base font-medium hover:text-gray-900 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                        )}
                      >
                        <span>Kunden</span>
                        <ChevronDownIcon
                          className={classNames(
                            open ? "text-gray-600" : "text-gray-400",
                            "ml-2 h-5 w-5 group-hover:text-gray-500 transition ease-in-out duration-150"
                          )}
                          aria-hidden="true"
                        />
                      </Popover.Button>

                      <Transition
                        as={Fragment}
                        enter="transition ease-out duration-200"
                        enterFrom="opacity-0 translate-y-1"
                        enterTo="opacity-100 translate-y-0"
                        leave="transition ease-in duration-150"
                        leaveFrom="opacity-100 translate-y-0"
                        leaveTo="opacity-0 translate-y-1"
                      >
                        <Popover.Panel className="absolute z-10 left-1/2 transform -translate-x-1/2 mt-3 px-2 w-screen max-w-md sm:px-0 lg:max-w-3xl">
                          <div className="rounded-lg shadow-lg ring-1 ring-black ring-opacity-5 overflow-hidden">
                            <div className="relative grid gap-6 bg-white px-5 py-6 sm:gap-8 sm:p-8 lg:grid-cols-2">
                              {solutions.map((item) => (
                                <a
                                  key={item.name}
                                  href={item.href}
                                  className="-m-3 p-3 flex items-start rounded-lg hover:bg-gray-50 transition ease-in-out duration-150"
                                >
                                  <div className="flex-shrink-0 flex items-center justify-center h-10 w-10 rounded-md bg-indigo-500 text-white sm:h-12 sm:w-12">
                                    <item.icon
                                      className="h-6 w-6"
                                      aria-hidden="true"
                                    />
                                  </div>
                                  <div className="ml-4">
                                    <p className="text-base font-medium text-gray-900">
                                      {item.name}
                                    </p>
                                    <p className="mt-1 text-sm text-gray-500">
                                      {item.description}
                                    </p>
                                  </div>
                                </a>
                              ))}
                            </div>
                            <div className="p-5 bg-gray-50 sm:p-8">
                              <a
                                href="#"
                                className="-m-3 p-3 flow-root rounded-md hover:bg-gray-100 transition ease-in-out duration-150"
                              >
                                <span className="flex items-center">
                                  <span className="text-base font-medium text-gray-900">
                                    Neuer Kunde anlegen
                                  </span>
                                  <span className="ml-3 inline-flex items-center px-3 py-0.5 rounded-full text-xs font-medium leading-5 bg-indigo-100 text-indigo-800">
                                    New
                                  </span>
                                </span>
                                <span className="mt-1 block text-sm text-gray-500">
                                  Lege Kunden an und setze die nötigen
                                  Einstellungen dazu an
                                </span>
                              </a>
                            </div>
                          </div>
                        </Popover.Panel>
                      </Transition>
                    </>
                  )}
                </Popover>
                <Popover className="relative mr-10">
                  {({ open }) => (
                    <>
                      <Popover.Button
                        className={classNames(
                          open ? "text-gray-900" : "text-gray-500",
                          "group bg-white rounded-md inline-flex items-center text-base font-medium hover:text-gray-900 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                        )}
                      >
                        <span>COMING SOON</span>
                        <ChevronDownIcon
                          className={classNames(
                            open ? "text-gray-600" : "text-gray-400",
                            "ml-2 h-5 w-5 group-hover:text-gray-500 transition ease-in-out duration-150"
                          )}
                          aria-hidden="true"
                        />
                      </Popover.Button>

                      <Transition
                        as={Fragment}
                        enter="transition ease-out duration-200"
                        enterFrom="opacity-0 translate-y-1"
                        enterTo="opacity-100 translate-y-0"
                        leave="transition ease-in duration-150"
                        leaveFrom="opacity-100 translate-y-0"
                        leaveTo="opacity-0 translate-y-1"
                      >
                        <Popover.Panel className="absolute z-10 left-1/2 transform -translate-x-1/2 mt-3 px-2 w-screen max-w-md sm:px-0 lg:max-w-3xl">
                          <div className="rounded-lg shadow-lg ring-1 ring-black ring-opacity-5 overflow-hidden"></div>
                        </Popover.Panel>
                      </Transition>
                    </>
                  )}
                </Popover>
                {/* Profile dropdown */}
                <Menu as="div" className="ml-3 relative z-10">
                  {({ open }) => (
                    <>
                      <div>
                        <Menu.Button className="bg-white flex text-sm rounded-full focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-brightBlue">
                          <span className="sr-only">
                            {t(TRANSLATION_LOCATION + "open_user_menu_sronly")}
                          </span>
                          <img
                            className="h-8 w-8 rounded-full"
                            src={ProfilePicture}
                            alt=""
                          />
                        </Menu.Button>
                      </div>
                      <Transition
                        show={open}
                        as={Fragment}
                        enter="transition ease-out duration-100"
                        enterFrom="transform opacity-0 scale-95"
                        enterTo="transform opacity-100 scale-100"
                        leave="transition ease-in duration-75"
                        leaveFrom="transform opacity-100 scale-100"
                        leaveTo="transform opacity-0 scale-95"
                      >
                        <Menu.Items
                          static
                          className="origin-top-right absolute right-0 mt-2 w-48 rounded-md shadow-lg py-1 bg-white ring-1 ring-black ring-opacity-5 focus:outline-none"
                        >
                          <div className="px-4 py-3 border-b">
                            <p className="text-sm">
                              {t(TRANSLATION_LOCATION + "signed_in_as")}
                            </p>
                            <p className="text-sm font-medium text-gray-900 truncate">
                              {cognitoUser?.attributes?.email}
                            </p>
                          </div>
                          <div className="py-1">
                            {userNavigation.map((item, index) => {
                              if (item.name === "Gruppe wechseln") {
                                return (
                                  <Menu.Item key={index}>
                                    {({ active }) => (
                                      <span
                                        onClick={() =>
                                          setVisibilityChooseGroup(true)
                                        }
                                        className={classNames(
                                          isActive(
                                            active || item.current,
                                            "grey"
                                          ),
                                          "block px-4 py-2 text-sm cursor-pointer"
                                        )}
                                      >
                                        {item.name}
                                      </span>
                                    )}
                                  </Menu.Item>
                                );
                              }
                              return (
                                <Menu.Item key={index}>
                                  {({ active }) => (
                                    <Link
                                      to={item.href}
                                      className={classNames(
                                        isActive(
                                          active || item.current,
                                          "grey"
                                        ),
                                        "block px-4 py-2 text-sm"
                                      )}
                                    >
                                      {item.name}
                                    </Link>
                                  )}
                                </Menu.Item>
                              );
                            })}
                          </div>
                        </Menu.Items>
                      </Transition>
                    </>
                  )}
                </Menu>
              </div>
              <div className="-mr-2 flex items-center sm:hidden">
                {/* Mobile menu button */}
                <Disclosure.Button className="bg-white inline-flex items-center justify-center p-2 rounded-md text-gray-400 hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">
                  <span className="sr-only">
                    {t(TRANSLATION_LOCATION + "open_main_menu_sronly")}
                  </span>
                  {open ? (
                    <XIcon className="block h-6 w-6" aria-hidden="true" />
                  ) : (
                    <MenuIcon className="block h-6 w-6" aria-hidden="true" />
                  )}
                </Disclosure.Button>
              </div>
            </div>
          </div>

          <Disclosure.Panel className="sm:hidden">
            <div className="pt-2 pb-3 space-y-1">
              {generalNavigation.map((item) => (
                <Link
                  key={item.name}
                  to={item.href}
                  className={classNames(
                    isActive(item.current, "indigo"),
                    "block pl-3 pr-4 py-2 border-l-4 text-base font-medium"
                  )}
                  aria-current={item.current ? "page" : undefined}
                >
                  {item.name}
                </Link>
              ))}
            </div>
            <div className="pt-4 pb-3 border-t border-gray-200">
              <div className="flex items-center px-4">
                <div className="flex-shrink-0">
                  <img
                    className="h-10 w-10 rounded-full"
                    src={ProfilePicture}
                    alt=""
                  />
                </div>
                <div className="ml-3">
                  <p className="text-sm">
                    {t(TRANSLATION_LOCATION + "signed_in_as")}
                  </p>
                  <p className="text-sm font-medium text-gray-900 truncate">
                    {cognitoUser?.attributes?.email}
                  </p>
                </div>
              </div>
              <div className="mt-3 space-y-1">
                {userNavigation.map((item, index) => (
                  <Link
                    key={index}
                    to={item.href}
                    className={classNames(
                      isActive(item.current, "indigo"),
                      "block pl-3 pr-4 py-2 border-l-4 text-base font-medium"
                    )}
                  >
                    {item.name}
                  </Link>
                ))}
              </div>
            </div>
          </Disclosure.Panel>
        </>
      )}
    </Disclosure>
  );
};

SiteNavigationContainer.propTypes = {
  userNavigation: PropTypes.array.isRequired,
  generalNavigation: PropTypes.array.isRequired,
  cognitoUser: PropTypes.object.isRequired,
};

export default SiteNavigationContainer;
