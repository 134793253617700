import React, { useRef, useState, Fragment } from "react";
import PropTypes from "prop-types";
import { PencilAltIcon } from "@heroicons/react/outline";
import { Transition, Dialog } from "@headlessui/react";
import { useFormik } from "formik";
import * as Yup from "yup";
import ActivityIndicator from "react-spinners/BeatLoader";
import { useTranslation } from "react-i18next";

import { colors } from "../constants/colors";
import Input from "./input";
import RadioListGroup from "./radio-list-group";
import { getUserTypes } from "../constants/user-types";

const TRANSLATION_LOCATION = "components.careers.career_name_dialog.";

const LeadDataDialog = ({ activeLead, setActiveLead, onClick, isLoading }) => {
  if (!activeLead) return null;
  const { t } = useTranslation();
  const cancelButtonRef = useRef(null);
  const [userType, setUserType] = useState("FREEMIUM");
  const [skipMail, setSkipMail] = useState(false);
  console.log("activeLead", activeLead);

  function getNameFromData() {
    console.log(JSON.parse(activeLead.data));
    const names = { firstname: "", lastname: "" };
    const parsedData = JSON.parse(activeLead.data);
    for (const key in parsedData) {
      if (parsedData[key].question?.includes("Nachname")) {
        names.lastname = parsedData[key].answer;
      }
      if (parsedData[key].question?.includes("Vorname")) {
        names.firstname = parsedData[key].answer;
      }
    }
    return names;
  }

  const { firstname, lastname } = getNameFromData();
  const formik = useFormik({
    initialValues: {
      firstname: firstname,
      lastname: lastname,
      companyName:
        activeLead.email.split("@")[1].split(".")[0].toUpperCase() || "",
    },
    onSubmit: (values) => {
      onClick({ ...values, userType, skipMail });
      closeModal();
    },
  });

  const closeModal = () => {
    formik.resetForm();
    setActiveLead(null);
  };

  return (
    <Transition.Root show={activeLead ? true : false} as={Fragment}>
      <Dialog
        as="div"
        static
        className="fixed z-10 inset-0 overflow-y-auto"
        initialFocus={cancelButtonRef}
        open={activeLead ? true : false}
        onClose={closeModal}
      >
        <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>

          {/* This element is to trick the browser into centering the modal contents. */}
          <span
            className="hidden sm:inline-block sm:align-middle sm:h-screen"
            aria-hidden="true"
          >
            &#8203;
          </span>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            enterTo="opacity-100 translate-y-0 sm:scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          >
            <div className="inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full sm:p-6">
              <div>
                <div className="mx-auto flex items-center justify-center h-6 w-6 ">
                  <PencilAltIcon className="h-6 w-6" aria-hidden="true" />
                </div>
                <div className="mt-3 text-center sm:mt-5">
                  <Dialog.Title
                    as="h3"
                    className="text-lg leading-6 font-medium text-gray-900"
                  >
                    {"Kundendaten"}
                  </Dialog.Title>
                </div>
              </div>
              <div className="mt-8 text-center">
                <p className="text-sm text-gray-800">{activeLead.email}</p>
              </div>
              <form onSubmit={formik.handleSubmit}>
                <div className="mt-4">
                  <Input
                    label="E-Mail"
                    value={activeLead.email}
                    id="email"
                    disabled
                  />
                </div>
                <div className="mt-4">
                  <Input
                    label="Unternehmensname"
                    onChange={formik.handleChange}
                    value={formik.values.companyName}
                    id="companyName"
                    error={formik.errors.companyName}
                    touched={formik.touched.companyName}
                    onBlur={formik.handleBlur}
                    placeholder={t(
                      TRANSLATION_LOCATION + "input_career_title_placeholder"
                    )}
                  />
                </div>
                <div className="mt-4">
                  <Input
                    label="Kontakt Vorname"
                    onChange={formik.handleChange}
                    value={formik.values.firstname}
                    id="firstname"
                    error={formik.errors.firstname}
                    touched={formik.touched.firstname}
                    onBlur={formik.handleBlur}
                    placeholder={"Alex"}
                  />
                </div>
                <div className="mt-4">
                  <Input
                    label="Kontakt Nachname"
                    onChange={formik.handleChange}
                    value={formik.values.lastname}
                    id="lastname"
                    error={formik.errors.lastname}
                    touched={formik.touched.lastname}
                    onBlur={formik.handleBlur}
                    placeholder={"Muster"}
                  />
                </div>
                <div className="mt-8 text-center">
                  <p className="text-sm text-gray-800">
                    {"Auswahl Nutzertypen"}
                  </p>
                </div>
                <div className="my-4">
                  <RadioListGroup
                    group={getUserTypes()}
                    id="userType"
                    selected={userType}
                    setSelected={setUserType}
                  />
                </div>
                <div className="mt-8 text-center">
                  <p className="text-sm text-gray-800">
                    {"Art der Bestätigung"}
                  </p>
                </div>
                <div className="my-4">
                  <RadioListGroup
                    group={[
                      {
                        id: false,
                        label: "Mail versandt",
                        description: "Es wird eine Mail an den Nutzer versandt",
                        available: true,
                      },
                      {
                        id: true,
                        label: "Kein Mail versandt",
                        description:
                          "Es wird nach Erstellung ein Passwort angezeigt welches dem Nutzer mitgeteilt werden kann in einer selbst verfassten E-Mail",
                        available: true,
                      },
                    ]}
                    id="skipMail"
                    selected={skipMail}
                    setSelected={setSkipMail}
                  />
                </div>
                <div className="mt-6 sm:mt-8 sm:grid sm:grid-cols-2 sm:gap-3 sm:grid-flow-row-dense">
                  <button
                    type="submit"
                    className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-indigo-600 text-base font-medium text-white hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:col-start-2 sm:text-sm"
                  >
                    {t(TRANSLATION_LOCATION + "create_career_submit_action")}
                  </button>
                  <button
                    type="button"
                    className="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:mt-0 sm:col-start-1 sm:text-sm"
                    onClick={closeModal}
                    ref={cancelButtonRef}
                  >
                    {t(TRANSLATION_LOCATION + "create_career_cancel_action")}
                  </button>
                </div>
                {isLoading && (
                  <div className="flex justify-center pt-4">
                    <ActivityIndicator color={colors.primaryBlue} />
                  </div>
                )}
              </form>
            </div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition.Root>
  );
};

LeadDataDialog.propTypes = {
  openDialog: PropTypes.bool,
  setOpenDialog: PropTypes.func.isRequired,
  isLoading: PropTypes.bool,
};

LeadDataDialog.defaultProps = {
  openDialog: false,
  isLoading: false,
};

export default LeadDataDialog;
