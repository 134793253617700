/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const pinpointControl = /* GraphQL */ `
  query PinpointControl(
    $action: String!
    $channel_type: CHANNEL_TYPES
    $user_id: ID!
    $address: String
    $attributes: AWSJSON
    $location: PinpointControlLocationInput
    $demographic: PinpointControlDemographicInput
  ) {
    pinpointControl(
      action: $action
      channel_type: $channel_type
      user_id: $user_id
      address: $address
      attributes: $attributes
      location: $location
      demographic: $demographic
    )
  }
`;
export const userControl = /* GraphQL */ `
  query UserControl(
    $user_type: USER_TYPES
    $company_name: String
    $lead_id: ID
    $firstname: String
    $lastname: String
    $role: String
    $groupname: String
    $email: String
    $action: String!
    $action_settings: ActionSettingsInput
  ) {
    userControl(
      user_type: $user_type
      company_name: $company_name
      lead_id: $lead_id
      firstname: $firstname
      lastname: $lastname
      role: $role
      groupname: $groupname
      email: $email
      action: $action
      action_settings: $action_settings
    )
  }
`;
export const inviteTalent = /* GraphQL */ `
  query InviteTalent(
    $sendMail: Boolean
    $career_id: ID
    $partner_user_id: ID
    $firstname: String
    $lastname: String
    $email: String
    $email_template: String
    $space_id: ID
  ) {
    inviteTalent(
      sendMail: $sendMail
      career_id: $career_id
      partner_user_id: $partner_user_id
      firstname: $firstname
      lastname: $lastname
      email: $email
      email_template: $email_template
      space_id: $space_id
    ) {
      id
      owner
      history {
        eventName
        beforeStatus
        statusOption
        afterStatus
        createdAt
        message
        eventFiredBy
      }
      newsletter
      progress
      partner_id
      authorized
      career_id
      partner_user_id
      language
      ref_link
      matching_score_to_space_career
      related_spaces
      person {
        id
        owner
        authorized
        invitation_firstname
        firstname
        invitation_lastname
        lastname
        invitation_email
        email
        user {
          id
          owner
          time_in_app
          type
          role
          invited_from
          authorized
          employee_at_partner
          opens_app
          opens_app_daily
          avg_challenge_rating
          gender
          content_categories
          push_career
          push_jobs
          push_companies
          push_exams
          push_events
          active_space_id
          delete_text
          privacy
          mail_stored
          phone_stored
          last_seen
          createdAt
          updatedAt
          career_right_swipe_rate
          suggested_hero
          hero
          push_token
          pinpoint_endpoint
          education_level
          work_experience
          remote
        }
        space {
          id
          owner
          newsletter
          progress
          partner_id
          authorized
          career_id
          partner_user_id
          language
          ref_link
          matching_score_to_space_career
          related_spaces
          invitation_id
          callback_url
          expiresAt
          created_with_service
          invitation_reminder_at
          createdAt
          updatedAt
        }
        createdAt
        updatedAt
        personUserId
        personSpaceId
      }
      invitation_id
      callback_url
      expiresAt
      created_with_service
      invitation_reminder_at
      createdAt
      updatedAt
    }
  }
`;
export const stripe = /* GraphQL */ `
  query Stripe(
    $billingPortalSession: StripeBillingPortalSessionInput
    $checkoutSessions: StripeCheckoutSessionsInput
    $paymentIntent: StripePaymentIntentInput
    $listProducts: StripelistProductsInput
    $listPrices: StripelistPricesInput
    $getProduct: StripeGetProductInput
    $createSubscription: StripeCreateSubscriptionInput
    $getSubscription: GetSubscriptionInput
    $listSubscriptions: ListSubscriptionsInput
    $listOpenIntensionForCustomer: ListOpenIntensionForCustomerInput
    $updateSubscription: UpdateSubscriptionInput
  ) {
    stripe(
      billingPortalSession: $billingPortalSession
      checkoutSessions: $checkoutSessions
      paymentIntent: $paymentIntent
      listProducts: $listProducts
      listPrices: $listPrices
      getProduct: $getProduct
      createSubscription: $createSubscription
      getSubscription: $getSubscription
      listSubscriptions: $listSubscriptions
      listOpenIntensionForCustomer: $listOpenIntensionForCustomer
      updateSubscription: $updateSubscription
    )
  }
`;
export const generatePdf = /* GraphQL */ `
  query GeneratePdf(
    $user_id: ID
    $career_id: ID
    $type: String
    $partner_user_id: ID
    $partner_id: ID
    $show_old_ui: Boolean
  ) {
    generatePdf(
      user_id: $user_id
      career_id: $career_id
      type: $type
      partner_user_id: $partner_user_id
      partner_id: $partner_id
      show_old_ui: $show_old_ui
    )
  }
`;
export const summarizeAnalyses = /* GraphQL */ `
  query SummarizeAnalyses($career_id: ID!, $force_update: Boolean) {
    summarizeAnalyses(career_id: $career_id, force_update: $force_update) {
      upadtedCareer {
        id
        name
        photo_url
        title
        status
        archived
        history {
          eventName
          before
          after
          createdAt
          message
          eventFiredBy
        }
        category
        use_in {
          product
          active
          expiresAt
          createdAt
          updatedAt
        }
        partner_id
        authorized
        partner_name
        external_link
        related_career
        R
        I
        A
        S
        E
        C
        BW0
        BW1
        BW2
        BW3
        is_cluster
        is_active
        app_settings {
          components
          challenges
          custom_apply_mail_template
          usedAlgorithm
          hello_text
          custom_data_policy
        }
        career_cluster_url
        forced_dimension
        career_analyse
        createdAt
        updatedAt
      }
      isUpdated
      analysesUsed
    }
  }
`;
export const addUserToGroup = /* GraphQL */ `
  query AddUserToGroup($groupName: String!, $username: String) {
    addUserToGroup(groupName: $groupName, username: $username)
  }
`;
export const matchMeIfYouCan = /* GraphQL */ `
  query MatchMeIfYouCan($partner_id: ID, $user_id: ID) {
    matchMeIfYouCan(partner_id: $partner_id, user_id: $user_id)
  }
`;
export const sqlQuery = /* GraphQL */ `
  query SqlQuery($queryString: String) {
    sqlQuery(queryString: $queryString) {
      Items
      EngineExecutionTimeInMillis
      DataScannedInBytes
      TotalExecutionTimeInMillis
      QueryQueueTimeInMillis
      QueryPlanningTimeInMillis
      ServiceProcessingTimeInMillis
      DataScannedInMB
      QueryCostInUSD
      Count
      QueryExecutionId
      S3Location
    }
  }
`;
export const sendMail = /* GraphQL */ `
  query SendMail($toAddresses: [String], $body: String, $subject: String) {
    sendMail(toAddresses: $toAddresses, body: $body, subject: $subject)
  }
`;
export const zscoreCalculationLambda = /* GraphQL */ `
  query ZscoreCalculationLambda {
    zscoreCalculationLambda
  }
`;
export const getMatchingCareers = /* GraphQL */ `
  query GetMatchingCareers(
    $partner_id: String
    $minMatchingScore: Int
    $riasec: AWSJSON
    $educational_level: String
    $limit: Int
    $user_level: Int
    $getUnseen: Boolean
  ) {
    getMatchingCareers(
      partner_id: $partner_id
      minMatchingScore: $minMatchingScore
      riasec: $riasec
      educational_level: $educational_level
      limit: $limit
      user_level: $user_level
      getUnseen: $getUnseen
    )
  }
`;
export const shortUrl = /* GraphQL */ `
  query ShortUrl(
    $query: QueryShortUrlQueryInput
    $body: QueryShortUrlBodyInput
  ) {
    shortUrl(query: $query, body: $body)
  }
`;
export const getSystem = /* GraphQL */ `
  query GetSystem($id: ID!) {
    getSystem(id: $id) {
      id
      type
      data
      createdAt
      updatedAt
    }
  }
`;
export const listSystems = /* GraphQL */ `
  query ListSystems(
    $filter: ModelSystemFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listSystems(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        type
        data
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getLeads = /* GraphQL */ `
  query GetLeads($id: ID!) {
    getLeads(id: $id) {
      id
      data
      email
      phone
      status
      lead_source
      partner_id
      campaign_url
      createdAt
      updatedAt
    }
  }
`;
export const listLeads = /* GraphQL */ `
  query ListLeads(
    $filter: ModelLeadsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listLeads(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        data
        email
        phone
        status
        lead_source
        partner_id
        campaign_url
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const leadByMail = /* GraphQL */ `
  query LeadByMail(
    $email: String!
    $sortDirection: ModelSortDirection
    $filter: ModelLeadsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    leadByMail(
      email: $email
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        data
        email
        phone
        status
        lead_source
        partner_id
        campaign_url
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getForm = /* GraphQL */ `
  query GetForm($id: ID!) {
    getForm(id: $id) {
      id
      data
      email
      phone
      status
      form_source
      campaign_url
      createdAt
      updatedAt
    }
  }
`;
export const listForms = /* GraphQL */ `
  query ListForms(
    $filter: ModelFormFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listForms(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        data
        email
        phone
        status
        form_source
        campaign_url
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getUser = /* GraphQL */ `
  query GetUser($id: ID!) {
    getUser(id: $id) {
      id
      owner
      time_in_app
      type
      role
      invited_from
      authorized
      employee_at_partner
      partner {
        id
        name
        accesses {
          nextToken
        }
        display_name
        authorized
        career_site_url
        user_type
        restricted
        lead_id
        payment_type
        external_payment_meta {
          external_payment_user_id
          external_payment_service
        }
        planned_new_hires_per_year
        app_settings {
          components
          challenges
          custom_apply_mail_template
          usedAlgorithm
          hello_text
          custom_data_policy
        }
        logo
        logo_ratio
        logo_alt
        logo_alt_ratio
        createdAt
        updatedAt
      }
      person {
        id
        owner
        authorized
        invitation_firstname
        firstname
        invitation_lastname
        lastname
        invitation_email
        email
        user {
          id
          owner
          time_in_app
          type
          role
          invited_from
          authorized
          employee_at_partner
          opens_app
          opens_app_daily
          avg_challenge_rating
          gender
          content_categories
          push_career
          push_jobs
          push_companies
          push_exams
          push_events
          active_space_id
          delete_text
          privacy
          mail_stored
          phone_stored
          last_seen
          createdAt
          updatedAt
          career_right_swipe_rate
          suggested_hero
          hero
          push_token
          pinpoint_endpoint
          education_level
          work_experience
          remote
        }
        space {
          id
          owner
          newsletter
          progress
          partner_id
          authorized
          career_id
          partner_user_id
          language
          ref_link
          matching_score_to_space_career
          related_spaces
          invitation_id
          callback_url
          expiresAt
          created_with_service
          invitation_reminder_at
          createdAt
          updatedAt
        }
        createdAt
        updatedAt
        personUserId
        personSpaceId
      }
      opens_app
      opens_app_daily
      avg_challenge_rating
      gender
      content_categories
      push_career
      push_jobs
      push_companies
      push_exams
      push_events
      active_space_id
      delete_text
      privacy
      mail_stored
      phone_stored
      last_seen
      createdAt
      updatedAt
      career_right_swipe_rate
      suggested_hero
      hero
      location {
        lat
        lon
        city
      }
      percentiles {
        BIG_FIVE_O_score
        BIG_FIVE_C_score
        BIG_FIVE_o_score
        BIG_FIVE_c_score
        BIG_FIVE_e_score
        BIG_FIVE_a_score
        BIG_FIVE_n_score
        BIG_FIVE_elevation
        RIASEC_IMAGES_elevation
        RIASEC_IMAGES_r_score
        RIASEC_IMAGES_i_score
        RIASEC_IMAGES_a_score
        RIASEC_IMAGES_s_score
        RIASEC_IMAGES_e_score
        RIASEC_IMAGES_c_score
        HEROS_JOURNEY_score_coopcomp
        HEROS_JOURNEY_score_orderchaos
        TRUST_score
        TROLLEY_score
        SPEEDACC_score
        FLYINGDUTCH_score
        RESPDECISION_score
        PATH_FINDER_score
        FLANKER_score
        EMOTIONS_GAME_score
        ANALOGICAL_REASONING_score
        TOWER_OF_LONDON_score
        DIGIT_SPAN_score
        NBACK_score
        FLANKER_avg_speed
      }
      reach_requests {
        items {
          id
          owner
          seen
          clicked
          authorized
          email
          career_url
          level
          ttl
          career_id
          partner_id
          createdAt
          updatedAt
        }
        nextToken
      }
      reach_settings {
        searching_type
        searching_distance
      }
      push_token
      pinpoint_endpoint
      education_level
      work_experience
      remote
    }
  }
`;
export const listUsers = /* GraphQL */ `
  query ListUsers(
    $filter: ModelUserFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listUsers(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        owner
        time_in_app
        type
        role
        invited_from
        authorized
        employee_at_partner
        partner {
          id
          name
          display_name
          authorized
          career_site_url
          user_type
          restricted
          lead_id
          payment_type
          planned_new_hires_per_year
          logo
          logo_ratio
          logo_alt
          logo_alt_ratio
          createdAt
          updatedAt
        }
        person {
          id
          owner
          authorized
          invitation_firstname
          firstname
          invitation_lastname
          lastname
          invitation_email
          email
          createdAt
          updatedAt
          personUserId
          personSpaceId
        }
        opens_app
        opens_app_daily
        avg_challenge_rating
        gender
        content_categories
        push_career
        push_jobs
        push_companies
        push_exams
        push_events
        active_space_id
        delete_text
        privacy
        mail_stored
        phone_stored
        last_seen
        createdAt
        updatedAt
        career_right_swipe_rate
        suggested_hero
        hero
        location {
          lat
          lon
          city
        }
        percentiles {
          BIG_FIVE_O_score
          BIG_FIVE_C_score
          BIG_FIVE_o_score
          BIG_FIVE_c_score
          BIG_FIVE_e_score
          BIG_FIVE_a_score
          BIG_FIVE_n_score
          BIG_FIVE_elevation
          RIASEC_IMAGES_elevation
          RIASEC_IMAGES_r_score
          RIASEC_IMAGES_i_score
          RIASEC_IMAGES_a_score
          RIASEC_IMAGES_s_score
          RIASEC_IMAGES_e_score
          RIASEC_IMAGES_c_score
          HEROS_JOURNEY_score_coopcomp
          HEROS_JOURNEY_score_orderchaos
          TRUST_score
          TROLLEY_score
          SPEEDACC_score
          FLYINGDUTCH_score
          RESPDECISION_score
          PATH_FINDER_score
          FLANKER_score
          EMOTIONS_GAME_score
          ANALOGICAL_REASONING_score
          TOWER_OF_LONDON_score
          DIGIT_SPAN_score
          NBACK_score
          FLANKER_avg_speed
        }
        reach_requests {
          nextToken
        }
        reach_settings {
          searching_type
          searching_distance
        }
        push_token
        pinpoint_endpoint
        education_level
        work_experience
        remote
      }
      nextToken
    }
  }
`;
export const employeeByPartner = /* GraphQL */ `
  query EmployeeByPartner(
    $employee_at_partner: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelUserFilterInput
    $limit: Int
    $nextToken: String
  ) {
    employeeByPartner(
      employee_at_partner: $employee_at_partner
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        owner
        time_in_app
        type
        role
        invited_from
        authorized
        employee_at_partner
        partner {
          id
          name
          display_name
          authorized
          career_site_url
          user_type
          restricted
          lead_id
          payment_type
          planned_new_hires_per_year
          logo
          logo_ratio
          logo_alt
          logo_alt_ratio
          createdAt
          updatedAt
        }
        person {
          id
          owner
          authorized
          invitation_firstname
          firstname
          invitation_lastname
          lastname
          invitation_email
          email
          createdAt
          updatedAt
          personUserId
          personSpaceId
        }
        opens_app
        opens_app_daily
        avg_challenge_rating
        gender
        content_categories
        push_career
        push_jobs
        push_companies
        push_exams
        push_events
        active_space_id
        delete_text
        privacy
        mail_stored
        phone_stored
        last_seen
        createdAt
        updatedAt
        career_right_swipe_rate
        suggested_hero
        hero
        location {
          lat
          lon
          city
        }
        percentiles {
          BIG_FIVE_O_score
          BIG_FIVE_C_score
          BIG_FIVE_o_score
          BIG_FIVE_c_score
          BIG_FIVE_e_score
          BIG_FIVE_a_score
          BIG_FIVE_n_score
          BIG_FIVE_elevation
          RIASEC_IMAGES_elevation
          RIASEC_IMAGES_r_score
          RIASEC_IMAGES_i_score
          RIASEC_IMAGES_a_score
          RIASEC_IMAGES_s_score
          RIASEC_IMAGES_e_score
          RIASEC_IMAGES_c_score
          HEROS_JOURNEY_score_coopcomp
          HEROS_JOURNEY_score_orderchaos
          TRUST_score
          TROLLEY_score
          SPEEDACC_score
          FLYINGDUTCH_score
          RESPDECISION_score
          PATH_FINDER_score
          FLANKER_score
          EMOTIONS_GAME_score
          ANALOGICAL_REASONING_score
          TOWER_OF_LONDON_score
          DIGIT_SPAN_score
          NBACK_score
          FLANKER_avg_speed
        }
        reach_requests {
          nextToken
        }
        reach_settings {
          searching_type
          searching_distance
        }
        push_token
        pinpoint_endpoint
        education_level
        work_experience
        remote
      }
      nextToken
    }
  }
`;
export const getPayment = /* GraphQL */ `
  query GetPayment($id: ID!) {
    getPayment(id: $id) {
      id
      partner_id
      authorized
      invoice_url
      product
      expires_at
      start_at
      price
      freemium
      payment_service
      external_payment_id
      payment_meta
      spaces_count
      career_count
      comment
      hirings_count
      owner
      createdAt
      updatedAt
    }
  }
`;
export const listPayments = /* GraphQL */ `
  query ListPayments(
    $filter: ModelPaymentFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listPayments(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        partner_id
        authorized
        invoice_url
        product
        expires_at
        start_at
        price
        freemium
        payment_service
        external_payment_id
        payment_meta
        spaces_count
        career_count
        comment
        hirings_count
        owner
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const paymentByPartnerIdByCreatedAt = /* GraphQL */ `
  query PaymentByPartnerIdByCreatedAt(
    $partner_id: ID!
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelPaymentFilterInput
    $limit: Int
    $nextToken: String
  ) {
    paymentByPartnerIdByCreatedAt(
      partner_id: $partner_id
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        partner_id
        authorized
        invoice_url
        product
        expires_at
        start_at
        price
        freemium
        payment_service
        external_payment_id
        payment_meta
        spaces_count
        career_count
        comment
        hirings_count
        owner
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getSpace = /* GraphQL */ `
  query GetSpace($id: ID!) {
    getSpace(id: $id) {
      id
      owner
      history {
        eventName
        beforeStatus
        statusOption
        afterStatus
        createdAt
        message
        eventFiredBy
      }
      newsletter
      progress
      partner_id
      authorized
      career_id
      partner_user_id
      language
      ref_link
      matching_score_to_space_career
      related_spaces
      person {
        id
        owner
        authorized
        invitation_firstname
        firstname
        invitation_lastname
        lastname
        invitation_email
        email
        user {
          id
          owner
          time_in_app
          type
          role
          invited_from
          authorized
          employee_at_partner
          opens_app
          opens_app_daily
          avg_challenge_rating
          gender
          content_categories
          push_career
          push_jobs
          push_companies
          push_exams
          push_events
          active_space_id
          delete_text
          privacy
          mail_stored
          phone_stored
          last_seen
          createdAt
          updatedAt
          career_right_swipe_rate
          suggested_hero
          hero
          push_token
          pinpoint_endpoint
          education_level
          work_experience
          remote
        }
        space {
          id
          owner
          newsletter
          progress
          partner_id
          authorized
          career_id
          partner_user_id
          language
          ref_link
          matching_score_to_space_career
          related_spaces
          invitation_id
          callback_url
          expiresAt
          created_with_service
          invitation_reminder_at
          createdAt
          updatedAt
        }
        createdAt
        updatedAt
        personUserId
        personSpaceId
      }
      invitation_id
      callback_url
      expiresAt
      created_with_service
      invitation_reminder_at
      createdAt
      updatedAt
    }
  }
`;
export const listSpaces = /* GraphQL */ `
  query ListSpaces(
    $filter: ModelSpaceFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listSpaces(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        owner
        history {
          eventName
          beforeStatus
          statusOption
          afterStatus
          createdAt
          message
          eventFiredBy
        }
        newsletter
        progress
        partner_id
        authorized
        career_id
        partner_user_id
        language
        ref_link
        matching_score_to_space_career
        related_spaces
        person {
          id
          owner
          authorized
          invitation_firstname
          firstname
          invitation_lastname
          lastname
          invitation_email
          email
          createdAt
          updatedAt
          personUserId
          personSpaceId
        }
        invitation_id
        callback_url
        expiresAt
        created_with_service
        invitation_reminder_at
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const spacesByOwner = /* GraphQL */ `
  query SpacesByOwner(
    $owner: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelSpaceFilterInput
    $limit: Int
    $nextToken: String
  ) {
    spacesByOwner(
      owner: $owner
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        owner
        history {
          eventName
          beforeStatus
          statusOption
          afterStatus
          createdAt
          message
          eventFiredBy
        }
        newsletter
        progress
        partner_id
        authorized
        career_id
        partner_user_id
        language
        ref_link
        matching_score_to_space_career
        related_spaces
        person {
          id
          owner
          authorized
          invitation_firstname
          firstname
          invitation_lastname
          lastname
          invitation_email
          email
          createdAt
          updatedAt
          personUserId
          personSpaceId
        }
        invitation_id
        callback_url
        expiresAt
        created_with_service
        invitation_reminder_at
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const spacesByPartnerIdByCreatedAt = /* GraphQL */ `
  query SpacesByPartnerIdByCreatedAt(
    $partner_id: ID!
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelSpaceFilterInput
    $limit: Int
    $nextToken: String
  ) {
    spacesByPartnerIdByCreatedAt(
      partner_id: $partner_id
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        owner
        history {
          eventName
          beforeStatus
          statusOption
          afterStatus
          createdAt
          message
          eventFiredBy
        }
        newsletter
        progress
        partner_id
        authorized
        career_id
        partner_user_id
        language
        ref_link
        matching_score_to_space_career
        related_spaces
        person {
          id
          owner
          authorized
          invitation_firstname
          firstname
          invitation_lastname
          lastname
          invitation_email
          email
          createdAt
          updatedAt
          personUserId
          personSpaceId
        }
        invitation_id
        callback_url
        expiresAt
        created_with_service
        invitation_reminder_at
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const spacesByCareerId = /* GraphQL */ `
  query SpacesByCareerId(
    $career_id: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelSpaceFilterInput
    $limit: Int
    $nextToken: String
  ) {
    spacesByCareerId(
      career_id: $career_id
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        owner
        history {
          eventName
          beforeStatus
          statusOption
          afterStatus
          createdAt
          message
          eventFiredBy
        }
        newsletter
        progress
        partner_id
        authorized
        career_id
        partner_user_id
        language
        ref_link
        matching_score_to_space_career
        related_spaces
        person {
          id
          owner
          authorized
          invitation_firstname
          firstname
          invitation_lastname
          lastname
          invitation_email
          email
          createdAt
          updatedAt
          personUserId
          personSpaceId
        }
        invitation_id
        callback_url
        expiresAt
        created_with_service
        invitation_reminder_at
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const spacesByPartnerUserIdByPartner = /* GraphQL */ `
  query SpacesByPartnerUserIdByPartner(
    $partner_user_id: String!
    $partner_id: ModelIDKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelSpaceFilterInput
    $limit: Int
    $nextToken: String
  ) {
    spacesByPartnerUserIDByPartner(
      partner_user_id: $partner_user_id
      partner_id: $partner_id
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        owner
        history {
          eventName
          beforeStatus
          statusOption
          afterStatus
          createdAt
          message
          eventFiredBy
        }
        newsletter
        progress
        partner_id
        authorized
        career_id
        partner_user_id
        language
        ref_link
        matching_score_to_space_career
        related_spaces
        person {
          id
          owner
          authorized
          invitation_firstname
          firstname
          invitation_lastname
          lastname
          invitation_email
          email
          createdAt
          updatedAt
          personUserId
          personSpaceId
        }
        invitation_id
        callback_url
        expiresAt
        created_with_service
        invitation_reminder_at
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getPerson = /* GraphQL */ `
  query GetPerson($id: ID!) {
    getPerson(id: $id) {
      id
      owner
      authorized
      invitation_firstname
      firstname
      invitation_lastname
      lastname
      invitation_email
      email
      user {
        id
        owner
        time_in_app
        type
        role
        invited_from
        authorized
        employee_at_partner
        partner {
          id
          name
          display_name
          authorized
          career_site_url
          user_type
          restricted
          lead_id
          payment_type
          planned_new_hires_per_year
          logo
          logo_ratio
          logo_alt
          logo_alt_ratio
          createdAt
          updatedAt
        }
        person {
          id
          owner
          authorized
          invitation_firstname
          firstname
          invitation_lastname
          lastname
          invitation_email
          email
          createdAt
          updatedAt
          personUserId
          personSpaceId
        }
        opens_app
        opens_app_daily
        avg_challenge_rating
        gender
        content_categories
        push_career
        push_jobs
        push_companies
        push_exams
        push_events
        active_space_id
        delete_text
        privacy
        mail_stored
        phone_stored
        last_seen
        createdAt
        updatedAt
        career_right_swipe_rate
        suggested_hero
        hero
        location {
          lat
          lon
          city
        }
        percentiles {
          BIG_FIVE_O_score
          BIG_FIVE_C_score
          BIG_FIVE_o_score
          BIG_FIVE_c_score
          BIG_FIVE_e_score
          BIG_FIVE_a_score
          BIG_FIVE_n_score
          BIG_FIVE_elevation
          RIASEC_IMAGES_elevation
          RIASEC_IMAGES_r_score
          RIASEC_IMAGES_i_score
          RIASEC_IMAGES_a_score
          RIASEC_IMAGES_s_score
          RIASEC_IMAGES_e_score
          RIASEC_IMAGES_c_score
          HEROS_JOURNEY_score_coopcomp
          HEROS_JOURNEY_score_orderchaos
          TRUST_score
          TROLLEY_score
          SPEEDACC_score
          FLYINGDUTCH_score
          RESPDECISION_score
          PATH_FINDER_score
          FLANKER_score
          EMOTIONS_GAME_score
          ANALOGICAL_REASONING_score
          TOWER_OF_LONDON_score
          DIGIT_SPAN_score
          NBACK_score
          FLANKER_avg_speed
        }
        reach_requests {
          nextToken
        }
        reach_settings {
          searching_type
          searching_distance
        }
        push_token
        pinpoint_endpoint
        education_level
        work_experience
        remote
      }
      space {
        id
        owner
        history {
          eventName
          beforeStatus
          statusOption
          afterStatus
          createdAt
          message
          eventFiredBy
        }
        newsletter
        progress
        partner_id
        authorized
        career_id
        partner_user_id
        language
        ref_link
        matching_score_to_space_career
        related_spaces
        person {
          id
          owner
          authorized
          invitation_firstname
          firstname
          invitation_lastname
          lastname
          invitation_email
          email
          createdAt
          updatedAt
          personUserId
          personSpaceId
        }
        invitation_id
        callback_url
        expiresAt
        created_with_service
        invitation_reminder_at
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
      personUserId
      personSpaceId
    }
  }
`;
export const listPeople = /* GraphQL */ `
  query ListPeople(
    $filter: ModelPersonFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listPeople(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        owner
        authorized
        invitation_firstname
        firstname
        invitation_lastname
        lastname
        invitation_email
        email
        user {
          id
          owner
          time_in_app
          type
          role
          invited_from
          authorized
          employee_at_partner
          opens_app
          opens_app_daily
          avg_challenge_rating
          gender
          content_categories
          push_career
          push_jobs
          push_companies
          push_exams
          push_events
          active_space_id
          delete_text
          privacy
          mail_stored
          phone_stored
          last_seen
          createdAt
          updatedAt
          career_right_swipe_rate
          suggested_hero
          hero
          push_token
          pinpoint_endpoint
          education_level
          work_experience
          remote
        }
        space {
          id
          owner
          newsletter
          progress
          partner_id
          authorized
          career_id
          partner_user_id
          language
          ref_link
          matching_score_to_space_career
          related_spaces
          invitation_id
          callback_url
          expiresAt
          created_with_service
          invitation_reminder_at
          createdAt
          updatedAt
        }
        createdAt
        updatedAt
        personUserId
        personSpaceId
      }
      nextToken
    }
  }
`;
export const getReachRequest = /* GraphQL */ `
  query GetReachRequest($id: ID!) {
    getReachRequest(id: $id) {
      id
      owner
      seen
      clicked
      authorized
      email
      career_url
      level
      ttl
      career_id
      career {
        id
        name
        photo_url
        title
        status
        archived
        history {
          eventName
          before
          after
          createdAt
          message
          eventFiredBy
        }
        category
        use_in {
          product
          active
          expiresAt
          createdAt
          updatedAt
        }
        partner_id
        authorized
        partner_name
        external_link
        related_career
        R
        I
        A
        S
        E
        C
        BW0
        BW1
        BW2
        BW3
        is_cluster
        is_active
        app_settings {
          components
          challenges
          custom_apply_mail_template
          usedAlgorithm
          hello_text
          custom_data_policy
        }
        career_cluster_url
        forced_dimension
        career_analyse
        createdAt
        updatedAt
      }
      partner_id
      partner {
        id
        name
        accesses {
          nextToken
        }
        display_name
        authorized
        career_site_url
        user_type
        restricted
        lead_id
        payment_type
        external_payment_meta {
          external_payment_user_id
          external_payment_service
        }
        planned_new_hires_per_year
        app_settings {
          components
          challenges
          custom_apply_mail_template
          usedAlgorithm
          hello_text
          custom_data_policy
        }
        logo
        logo_ratio
        logo_alt
        logo_alt_ratio
        createdAt
        updatedAt
      }
      user {
        id
        owner
        time_in_app
        type
        role
        invited_from
        authorized
        employee_at_partner
        partner {
          id
          name
          display_name
          authorized
          career_site_url
          user_type
          restricted
          lead_id
          payment_type
          planned_new_hires_per_year
          logo
          logo_ratio
          logo_alt
          logo_alt_ratio
          createdAt
          updatedAt
        }
        person {
          id
          owner
          authorized
          invitation_firstname
          firstname
          invitation_lastname
          lastname
          invitation_email
          email
          createdAt
          updatedAt
          personUserId
          personSpaceId
        }
        opens_app
        opens_app_daily
        avg_challenge_rating
        gender
        content_categories
        push_career
        push_jobs
        push_companies
        push_exams
        push_events
        active_space_id
        delete_text
        privacy
        mail_stored
        phone_stored
        last_seen
        createdAt
        updatedAt
        career_right_swipe_rate
        suggested_hero
        hero
        location {
          lat
          lon
          city
        }
        percentiles {
          BIG_FIVE_O_score
          BIG_FIVE_C_score
          BIG_FIVE_o_score
          BIG_FIVE_c_score
          BIG_FIVE_e_score
          BIG_FIVE_a_score
          BIG_FIVE_n_score
          BIG_FIVE_elevation
          RIASEC_IMAGES_elevation
          RIASEC_IMAGES_r_score
          RIASEC_IMAGES_i_score
          RIASEC_IMAGES_a_score
          RIASEC_IMAGES_s_score
          RIASEC_IMAGES_e_score
          RIASEC_IMAGES_c_score
          HEROS_JOURNEY_score_coopcomp
          HEROS_JOURNEY_score_orderchaos
          TRUST_score
          TROLLEY_score
          SPEEDACC_score
          FLYINGDUTCH_score
          RESPDECISION_score
          PATH_FINDER_score
          FLANKER_score
          EMOTIONS_GAME_score
          ANALOGICAL_REASONING_score
          TOWER_OF_LONDON_score
          DIGIT_SPAN_score
          NBACK_score
          FLANKER_avg_speed
        }
        reach_requests {
          nextToken
        }
        reach_settings {
          searching_type
          searching_distance
        }
        push_token
        pinpoint_endpoint
        education_level
        work_experience
        remote
      }
      createdAt
      updatedAt
    }
  }
`;
export const listReachRequests = /* GraphQL */ `
  query ListReachRequests(
    $filter: ModelReachRequestFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listReachRequests(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        owner
        seen
        clicked
        authorized
        email
        career_url
        level
        ttl
        career_id
        career {
          id
          name
          photo_url
          title
          status
          archived
          category
          partner_id
          authorized
          partner_name
          external_link
          related_career
          R
          I
          A
          S
          E
          C
          BW0
          BW1
          BW2
          BW3
          is_cluster
          is_active
          career_cluster_url
          forced_dimension
          career_analyse
          createdAt
          updatedAt
        }
        partner_id
        partner {
          id
          name
          display_name
          authorized
          career_site_url
          user_type
          restricted
          lead_id
          payment_type
          planned_new_hires_per_year
          logo
          logo_ratio
          logo_alt
          logo_alt_ratio
          createdAt
          updatedAt
        }
        user {
          id
          owner
          time_in_app
          type
          role
          invited_from
          authorized
          employee_at_partner
          opens_app
          opens_app_daily
          avg_challenge_rating
          gender
          content_categories
          push_career
          push_jobs
          push_companies
          push_exams
          push_events
          active_space_id
          delete_text
          privacy
          mail_stored
          phone_stored
          last_seen
          createdAt
          updatedAt
          career_right_swipe_rate
          suggested_hero
          hero
          push_token
          pinpoint_endpoint
          education_level
          work_experience
          remote
        }
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getBug = /* GraphQL */ `
  query GetBug($id: ID!) {
    getBug(id: $id) {
      owner
      assigned_to
      os
      category
      status
      screenshots
      description
      createdAt
      updatedAt
      id
    }
  }
`;
export const listBugs = /* GraphQL */ `
  query ListBugs(
    $filter: ModelBugFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listBugs(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        owner
        assigned_to
        os
        category
        status
        screenshots
        description
        createdAt
        updatedAt
        id
      }
      nextToken
    }
  }
`;
export const getAnswer = /* GraphQL */ `
  query GetAnswer($id: ID!) {
    getAnswer(id: $id) {
      id
      owner
      exam_id
      config {
        id
        answers {
          id
          owner
          exam_id
          cycle
          user_input
          aivy_output
          rating
          rating_info
          result_rating_info
          score
          version
          attempts
          space_id
          os
          createdAt
          updatedAt
        }
        item_count
        zscore_mean
        zscore_deviation
        heighest_score_answer
        version
        backgroundColor
        title
        timeToComplete
        hasTestRounds
        cardIcon
        cardIconWidth
        cardIconHeight
        image
        createdAt
        updatedAt
      }
      cycle
      user_input
      aivy_output
      rating
      rating_info
      result_rating_info
      score
      version
      attempts
      space_id
      os
      createdAt
      updatedAt
    }
  }
`;
export const listAnswers = /* GraphQL */ `
  query ListAnswers(
    $filter: ModelAnswerFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listAnswers(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        owner
        exam_id
        config {
          id
          item_count
          zscore_mean
          zscore_deviation
          heighest_score_answer
          version
          backgroundColor
          title
          timeToComplete
          hasTestRounds
          cardIcon
          cardIconWidth
          cardIconHeight
          image
          createdAt
          updatedAt
        }
        cycle
        user_input
        aivy_output
        rating
        rating_info
        result_rating_info
        score
        version
        attempts
        space_id
        os
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const answersByOwner = /* GraphQL */ `
  query AnswersByOwner(
    $owner: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelAnswerFilterInput
    $limit: Int
    $nextToken: String
  ) {
    answersByOwner(
      owner: $owner
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        owner
        exam_id
        config {
          id
          item_count
          zscore_mean
          zscore_deviation
          heighest_score_answer
          version
          backgroundColor
          title
          timeToComplete
          hasTestRounds
          cardIcon
          cardIconWidth
          cardIconHeight
          image
          createdAt
          updatedAt
        }
        cycle
        user_input
        aivy_output
        rating
        rating_info
        result_rating_info
        score
        version
        attempts
        space_id
        os
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const answersByExamIdByDate = /* GraphQL */ `
  query AnswersByExamIdByDate(
    $exam_id: ID!
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelAnswerFilterInput
    $limit: Int
    $nextToken: String
  ) {
    answersByExamIdByDate(
      exam_id: $exam_id
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        owner
        exam_id
        config {
          id
          item_count
          zscore_mean
          zscore_deviation
          heighest_score_answer
          version
          backgroundColor
          title
          timeToComplete
          hasTestRounds
          cardIcon
          cardIconWidth
          cardIconHeight
          image
          createdAt
          updatedAt
        }
        cycle
        user_input
        aivy_output
        rating
        rating_info
        result_rating_info
        score
        version
        attempts
        space_id
        os
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getExamConfig = /* GraphQL */ `
  query GetExamConfig($id: ID!) {
    getExamConfig(id: $id) {
      id
      answers {
        id
        owner
        exam_id
        config {
          id
          item_count
          zscore_mean
          zscore_deviation
          heighest_score_answer
          version
          backgroundColor
          title
          timeToComplete
          hasTestRounds
          cardIcon
          cardIconWidth
          cardIconHeight
          image
          createdAt
          updatedAt
        }
        cycle
        user_input
        aivy_output
        rating
        rating_info
        result_rating_info
        score
        version
        attempts
        space_id
        os
        createdAt
        updatedAt
      }
      item_count
      zscore_mean
      zscore_deviation
      heighest_score_answer
      version
      backgroundColor
      title
      timeToComplete
      hasTestRounds
      cardIcon
      cardIconWidth
      cardIconHeight
      image
      createdAt
      updatedAt
    }
  }
`;
export const listExamConfigs = /* GraphQL */ `
  query ListExamConfigs(
    $filter: ModelExamConfigFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listExamConfigs(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        answers {
          id
          owner
          exam_id
          cycle
          user_input
          aivy_output
          rating
          rating_info
          result_rating_info
          score
          version
          attempts
          space_id
          os
          createdAt
          updatedAt
        }
        item_count
        zscore_mean
        zscore_deviation
        heighest_score_answer
        version
        backgroundColor
        title
        timeToComplete
        hasTestRounds
        cardIcon
        cardIconWidth
        cardIconHeight
        image
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getCareerAnalyse = /* GraphQL */ `
  query GetCareerAnalyse($id: ID!) {
    getCareerAnalyse(id: $id) {
      id
      input
      verified
      status
      career_id
      partner_id
      authorized
      person_id
      person {
        id
        owner
        authorized
        invitation_firstname
        firstname
        invitation_lastname
        lastname
        invitation_email
        email
        user {
          id
          owner
          time_in_app
          type
          role
          invited_from
          authorized
          employee_at_partner
          opens_app
          opens_app_daily
          avg_challenge_rating
          gender
          content_categories
          push_career
          push_jobs
          push_companies
          push_exams
          push_events
          active_space_id
          delete_text
          privacy
          mail_stored
          phone_stored
          last_seen
          createdAt
          updatedAt
          career_right_swipe_rate
          suggested_hero
          hero
          push_token
          pinpoint_endpoint
          education_level
          work_experience
          remote
        }
        space {
          id
          owner
          newsletter
          progress
          partner_id
          authorized
          career_id
          partner_user_id
          language
          ref_link
          matching_score_to_space_career
          related_spaces
          invitation_id
          callback_url
          expiresAt
          created_with_service
          invitation_reminder_at
          createdAt
          updatedAt
        }
        createdAt
        updatedAt
        personUserId
        personSpaceId
      }
      url_params
      createdAt
      updatedAt
    }
  }
`;
export const listCareerAnalyses = /* GraphQL */ `
  query ListCareerAnalyses(
    $filter: ModelCareerAnalyseFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listCareerAnalyses(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        input
        verified
        status
        career_id
        partner_id
        authorized
        person_id
        person {
          id
          owner
          authorized
          invitation_firstname
          firstname
          invitation_lastname
          lastname
          invitation_email
          email
          createdAt
          updatedAt
          personUserId
          personSpaceId
        }
        url_params
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const careerAnalyseByCareer = /* GraphQL */ `
  query CareerAnalyseByCareer(
    $career_id: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelCareerAnalyseFilterInput
    $limit: Int
    $nextToken: String
  ) {
    careerAnalyseByCareer(
      career_id: $career_id
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        input
        verified
        status
        career_id
        partner_id
        authorized
        person_id
        person {
          id
          owner
          authorized
          invitation_firstname
          firstname
          invitation_lastname
          lastname
          invitation_email
          email
          createdAt
          updatedAt
          personUserId
          personSpaceId
        }
        url_params
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const careerAnalyseByPartner = /* GraphQL */ `
  query CareerAnalyseByPartner(
    $partner_id: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelCareerAnalyseFilterInput
    $limit: Int
    $nextToken: String
  ) {
    careerAnalyseByPartner(
      partner_id: $partner_id
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        input
        verified
        status
        career_id
        partner_id
        authorized
        person_id
        person {
          id
          owner
          authorized
          invitation_firstname
          firstname
          invitation_lastname
          lastname
          invitation_email
          email
          createdAt
          updatedAt
          personUserId
          personSpaceId
        }
        url_params
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getApiKey = /* GraphQL */ `
  query GetApiKey($id: ID!) {
    getApiKey(id: $id) {
      id
      owner
      partner_id
      service
      auth_token
      api_call_count
      createdAt
      updatedAt
    }
  }
`;
export const listApiKeys = /* GraphQL */ `
  query ListApiKeys(
    $filter: ModelApiKeyFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listApiKeys(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        owner
        partner_id
        service
        auth_token
        api_call_count
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const apiKeysByPartner = /* GraphQL */ `
  query ApiKeysByPartner(
    $partner_id: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelApiKeyFilterInput
    $limit: Int
    $nextToken: String
  ) {
    apiKeysByPartner(
      partner_id: $partner_id
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        owner
        partner_id
        service
        auth_token
        api_call_count
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const apiKeyByAuthToken = /* GraphQL */ `
  query ApiKeyByAuthToken(
    $auth_token: String!
    $sortDirection: ModelSortDirection
    $filter: ModelApiKeyFilterInput
    $limit: Int
    $nextToken: String
  ) {
    apiKeyByAuthToken(
      auth_token: $auth_token
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        owner
        partner_id
        service
        auth_token
        api_call_count
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getCareer = /* GraphQL */ `
  query GetCareer($id: ID!) {
    getCareer(id: $id) {
      id
      name
      photo_url
      title
      status
      archived
      history {
        eventName
        before
        after
        createdAt
        message
        eventFiredBy
      }
      category
      use_in {
        product
        active
        expiresAt
        createdAt
        updatedAt
      }
      partner_id
      authorized
      partner_name
      external_link
      related_career
      R
      I
      A
      S
      E
      C
      BW0
      BW1
      BW2
      BW3
      is_cluster
      is_active
      app_settings {
        components
        challenges
        custom_apply_mail_template
        custom_invite_talent_mail_templates {
          subject
          text
          id
          createdAt
          updatedAt
          standard
        }
        usedAlgorithm
        hello_text
        custom_data_policy
        notifications {
          webhook
          mail
        }
      }
      career_cluster_url
      forced_dimension
      career_analyse
      createdAt
      updatedAt
    }
  }
`;
export const listCareers = /* GraphQL */ `
  query ListCareers(
    $filter: ModelCareerFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listCareers(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        photo_url
        title
        status
        archived
        history {
          eventName
          before
          after
          createdAt
          message
          eventFiredBy
        }
        category
        use_in {
          product
          active
          expiresAt
          createdAt
          updatedAt
        }
        partner_id
        authorized
        partner_name
        external_link
        related_career
        R
        I
        A
        S
        E
        C
        BW0
        BW1
        BW2
        BW3
        is_cluster
        is_active
        app_settings {
          components
          challenges
          custom_apply_mail_template
          usedAlgorithm
          hello_text
          custom_data_policy
        }
        career_cluster_url
        forced_dimension
        career_analyse
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const careerByPartnerByDate = /* GraphQL */ `
  query CareerByPartnerByDate(
    $partner_id: ID!
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelCareerFilterInput
    $limit: Int
    $nextToken: String
  ) {
    careerByPartnerByDate(
      partner_id: $partner_id
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        name
        photo_url
        title
        status
        archived
        history {
          eventName
          before
          after
          createdAt
          message
          eventFiredBy
        }
        category
        use_in {
          product
          active
          expiresAt
          createdAt
          updatedAt
        }
        partner_id
        authorized
        partner_name
        external_link
        related_career
        R
        I
        A
        S
        E
        C
        BW0
        BW1
        BW2
        BW3
        is_cluster
        is_active
        app_settings {
          components
          challenges
          custom_apply_mail_template
          usedAlgorithm
          hello_text
          custom_data_policy
        }
        career_cluster_url
        forced_dimension
        career_analyse
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getEvent = /* GraphQL */ `
  query GetEvent($id: ID!) {
    getEvent(id: $id) {
      id
      title
      location {
        lat
        lon
        city
      }
      city
      postalcode
      adress
      location_name
      referlink
      price
      hashtags
      price_subtitle
      thumb
      description
      start_date
      end_date
      start_time
      end_time
      time_infos
      facebook
      twitter
      instagram
      youtube
      images
      categories
      createdAt
      updatedAt
    }
  }
`;
export const listEvents = /* GraphQL */ `
  query ListEvents(
    $filter: ModelEventFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listEvents(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        title
        location {
          lat
          lon
          city
        }
        city
        postalcode
        adress
        location_name
        referlink
        price
        hashtags
        price_subtitle
        thumb
        description
        start_date
        end_date
        start_time
        end_time
        time_infos
        facebook
        twitter
        instagram
        youtube
        images
        categories
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getEventOrganizer = /* GraphQL */ `
  query GetEventOrganizer($id: ID!) {
    getEventOrganizer(id: $id) {
      id
      name
      logo
      adress
      website
      phone
      mail
      is_partner
      createdAt
      updatedAt
    }
  }
`;
export const listEventOrganizers = /* GraphQL */ `
  query ListEventOrganizers(
    $filter: ModelEventOrganizerFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listEventOrganizers(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        logo
        adress
        website
        phone
        mail
        is_partner
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getArticle = /* GraphQL */ `
  query GetArticle($id: ID!) {
    getArticle(id: $id) {
      id
      title
      thumb
      subtitle
      images
      categories
      content
      createdAt
      updatedAt
    }
  }
`;
export const listArticles = /* GraphQL */ `
  query ListArticles(
    $filter: ModelArticleFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listArticles(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        title
        thumb
        subtitle
        images
        categories
        content
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getJob = /* GraphQL */ `
  query GetJob($id: ID!) {
    getJob(id: $id) {
      id
      company_id
      company_logo
      company_name
      affiliation_to_careers
      address
      title
      description
      applicationinfo {
        emails
        instruction
        uris
      }
      partner
      job_image
      extern_offer
      degree_types
      employment_type
      language_code
      job_start_time
      job_end_time
      job_publish_time
      job_expire_time
      ttl
      job_level
      promotion_value
      qualifications
      location {
        lat
        lon
        city
      }
      createdAt
      updatedAt
    }
  }
`;
export const listJobs = /* GraphQL */ `
  query ListJobs(
    $filter: ModelJobFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listJobs(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        company_id
        company_logo
        company_name
        affiliation_to_careers
        address
        title
        description
        applicationinfo {
          emails
          instruction
          uris
        }
        partner
        job_image
        extern_offer
        degree_types
        employment_type
        language_code
        job_start_time
        job_end_time
        job_publish_time
        job_expire_time
        ttl
        job_level
        promotion_value
        qualifications
        location {
          lat
          lon
          city
        }
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getApplicationInteract = /* GraphQL */ `
  query GetApplicationInteract($id: ID!) {
    getApplicationInteract(id: $id) {
      id
      owner
      owner_name
      company_logo
      company_id
      application_status
      address
      title
      description
      applicationinfo {
        emails
        instruction
        uris
      }
      partner
      job_image
      extern_offer
      degree_types
      employment_type
      language_code
      job_start_time
      job_end_time
      job_publish_time
      job_expire_time
      job_level
      promotion_value
      qualifications
      location {
        lat
        lon
        city
      }
      createdAt
      updatedAt
    }
  }
`;
export const listApplicationInteracts = /* GraphQL */ `
  query ListApplicationInteracts(
    $filter: ModelApplicationInteractFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listApplicationInteracts(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        owner
        owner_name
        company_logo
        company_id
        application_status
        address
        title
        description
        applicationinfo {
          emails
          instruction
          uris
        }
        partner
        job_image
        extern_offer
        degree_types
        employment_type
        language_code
        job_start_time
        job_end_time
        job_publish_time
        job_expire_time
        job_level
        promotion_value
        qualifications
        location {
          lat
          lon
          city
        }
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const applicationInteractByOwner = /* GraphQL */ `
  query ApplicationInteractByOwner(
    $owner: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelApplicationInteractFilterInput
    $limit: Int
    $nextToken: String
  ) {
    applicationInteractByOwner(
      owner: $owner
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        owner
        owner_name
        company_logo
        company_id
        application_status
        address
        title
        description
        applicationinfo {
          emails
          instruction
          uris
        }
        partner
        job_image
        extern_offer
        degree_types
        employment_type
        language_code
        job_start_time
        job_end_time
        job_publish_time
        job_expire_time
        job_level
        promotion_value
        qualifications
        location {
          lat
          lon
          city
        }
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getPartner = /* GraphQL */ `
  query GetPartner($id: ID!) {
    getPartner(id: $id) {
      id
      name
      accesses {
        items {
          id
          owner
          time_in_app
          type
          role
          invited_from
          authorized
          employee_at_partner
          opens_app
          opens_app_daily
          avg_challenge_rating
          gender
          content_categories
          push_career
          push_jobs
          push_companies
          push_exams
          push_events
          active_space_id
          delete_text
          privacy
          mail_stored
          phone_stored
          last_seen
          createdAt
          updatedAt
          career_right_swipe_rate
          suggested_hero
          hero
          push_token
          pinpoint_endpoint
          education_level
          work_experience
          remote
        }
        nextToken
      }
      display_name
      authorized
      career_site_url
      user_type
      restricted
      lead_id
      payment_type
      external_payment_meta {
        external_payment_user_id
        external_payment_service
      }
      planned_new_hires_per_year
      app_settings {
        components
        challenges
        custom_apply_mail_template
        custom_invite_talent_mail_templates {
          subject
          text
          id
          createdAt
          updatedAt
          standard
        }
        usedAlgorithm
        hello_text
        custom_data_policy
        notifications {
          webhook
          mail
        }
      }
      logo
      logo_ratio
      logo_alt
      logo_alt_ratio
      createdAt
      updatedAt
    }
  }
`;
export const listPartners = /* GraphQL */ `
  query ListPartners(
    $filter: ModelPartnerFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listPartners(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        accesses {
          nextToken
        }
        display_name
        authorized
        career_site_url
        user_type
        restricted
        lead_id
        payment_type
        external_payment_meta {
          external_payment_user_id
          external_payment_service
        }
        planned_new_hires_per_year
        app_settings {
          components
          challenges
          custom_apply_mail_template
          usedAlgorithm
          hello_text
          custom_data_policy
        }
        logo
        logo_ratio
        logo_alt
        logo_alt_ratio
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getService = /* GraphQL */ `
  query GetService($id: ID!) {
    getService(id: $id) {
      id
      type
      name
      token
      createdAt
      updatedAt
    }
  }
`;
export const listServices = /* GraphQL */ `
  query ListServices(
    $filter: ModelServiceFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listServices(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        type
        name
        token
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getCompany = /* GraphQL */ `
  query GetCompany($id: ID!) {
    getCompany(id: $id) {
      id
      headerimage
      google_id
      founding_year
      instagram
      youtube
      twitter
      adress
      images
      postalcode
      description
      city
      company_size
      location {
        lat
        lon
        city
      }
      website_uri
      career_site_uri
      sector
      logo
      name
      partner
      createdAt
      updatedAt
    }
  }
`;
export const listCompanies = /* GraphQL */ `
  query ListCompanies(
    $filter: ModelCompanyFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listCompanies(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        headerimage
        google_id
        founding_year
        instagram
        youtube
        twitter
        adress
        images
        postalcode
        description
        city
        company_size
        location {
          lat
          lon
          city
        }
        website_uri
        career_site_uri
        sector
        logo
        name
        partner
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const companyByName = /* GraphQL */ `
  query CompanyByName(
    $name: String!
    $sortDirection: ModelSortDirection
    $filter: ModelCompanyFilterInput
    $limit: Int
    $nextToken: String
  ) {
    companyByName(
      name: $name
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        headerimage
        google_id
        founding_year
        instagram
        youtube
        twitter
        adress
        images
        postalcode
        description
        city
        company_size
        location {
          lat
          lon
          city
        }
        website_uri
        career_site_uri
        sector
        logo
        name
        partner
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const companyIsPartner = /* GraphQL */ `
  query CompanyIsPartner(
    $partner: String!
    $sortDirection: ModelSortDirection
    $filter: ModelCompanyFilterInput
    $limit: Int
    $nextToken: String
  ) {
    companyIsPartner(
      partner: $partner
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        headerimage
        google_id
        founding_year
        instagram
        youtube
        twitter
        adress
        images
        postalcode
        description
        city
        company_size
        location {
          lat
          lon
          city
        }
        website_uri
        career_site_uri
        sector
        logo
        name
        partner
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getCompanyinteract = /* GraphQL */ `
  query GetCompanyinteract($id: ID!) {
    getCompanyinteract(id: $id) {
      id
      decision
      owner
      company_id
      createdAt
      updatedAt
    }
  }
`;
export const listCompanyinteracts = /* GraphQL */ `
  query ListCompanyinteracts(
    $filter: ModelCompanyinteractFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listCompanyinteracts(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        decision
        owner
        company_id
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getCareerinteract = /* GraphQL */ `
  query GetCareerinteract($id: ID!) {
    getCareerinteract(id: $id) {
      id
      owner
      career_id
      decision
      randomCard
      score
      createdAt
      updatedAt
    }
  }
`;
export const listCareerinteracts = /* GraphQL */ `
  query ListCareerinteracts(
    $filter: ModelCareerinteractFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listCareerinteracts(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        owner
        career_id
        decision
        randomCard
        score
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const careerInteractByOwnerByDecision = /* GraphQL */ `
  query CareerInteractByOwnerByDecision(
    $owner: ID!
    $decision: ModelIntKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelCareerinteractFilterInput
    $limit: Int
    $nextToken: String
  ) {
    careerInteractByOwnerByDecision(
      owner: $owner
      decision: $decision
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        owner
        career_id
        decision
        randomCard
        score
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const careerInteractByDecisionByDate = /* GraphQL */ `
  query CareerInteractByDecisionByDate(
    $decision: Int!
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelCareerinteractFilterInput
    $limit: Int
    $nextToken: String
  ) {
    careerInteractByDecisionByDate(
      decision: $decision
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        owner
        career_id
        decision
        randomCard
        score
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
