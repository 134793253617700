import React, { useEffect } from 'react'
import { Auth, API, graphqlOperation } from 'aws-amplify'

import * as mutations from '../graphql/mutations'

import { notifyBugsnag } from '../helpers'

const WindowFocusHandler = ({ userProfile }) => {
  useEffect(() => {
    // user has switched back to the tab
    const onFocus = () => {
      Auth.currentSession()
        .then(() => setLastSeenStatus(userProfile))
        .catch((err) => {
          if (err === 'No current user') {
            return window.location.reload()
          }

          notifyBugsnag(err)
        })
    }

    // user has switched away from the tab (tab is hidden)
    const onBlur = () => {
      // we do not use this function at the moment
    }

    window.addEventListener('focus', onFocus)
    window.addEventListener('blur', onBlur)

    return () => {
      window.removeEventListener('focus', onFocus)
      window.removeEventListener('blur', onBlur)
    }
  })

  const setLastSeenStatus = async (userprofile) => {
    if (!userprofile) return
    try {
      API.graphql(
        graphqlOperation(mutations.updateUser, {
          input: {
            id: userprofile.id,
            last_seen: new Date()
          }
        })
      )
    } catch (error) {
      notifyBugsnag(error)
    }
  }

  return <></>
}

// https://stackoverflow.com/a/54820741/16975091
export default WindowFocusHandler
