import i18n from 'i18next'
import { initReactI18next } from 'react-i18next'
import { BUILD_ENV } from './helpers'
import de from './i18n/de.json'
// import en from './i18n/en.json'

const resources = {
  // en: {
  //  translation: en
  // },
  de: {
    translation: de
  }
}

i18n
  .use(initReactI18next)
  // init i18next
  // for all options read: https://www.i18next.com/overview/configuration-options
  .init({
    resources,
    fallbackLng: 'de',
    debug: BUILD_ENV === 'develop',

    interpolation: {
      escapeValue: false // not needed for react as it escapes by default
    }
  })

export default i18n
