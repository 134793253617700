import {
  Fragment,
  forwardRef,
  useState,
  useEffect,
  useImperativeHandle
} from 'react'
import { Dialog, Transition } from '@headlessui/react'
import { isEmpty } from 'lodash'

import { classNames } from '../helpers'

const Modal = forwardRef((props, ref) => {
  const [visible, setVisible] = useState(false)
  const [alertData, setAlertData] = useState({})

  useEffect(() => {
    if (isEmpty(alertData)) return
    setVisible(true)
  }, [alertData])

  useImperativeHandle(ref, () => ({
    alert (title, message, buttons, iconComponent, children, maxwmax) {
      setAlertData({ title, message, buttons, iconComponent, children, maxwmax })
    }
  }))

  return (
    <Transition.Root show={visible} as={Fragment}>
      <Dialog
        as='div'
        auto-reopen='true'
        className='fixed z-10 inset-0 overflow-y-auto'
        onClose={setVisible}
      >
        <div className='flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0'>
          <Transition.Child
            as={Fragment}
            enter='ease-out duration-300'
            enterFrom='opacity-0'
            enterTo='opacity-100'
            leave='ease-in duration-200'
            leaveFrom='opacity-100'
            leaveTo='opacity-0'
          >
            <Dialog.Overlay className='fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity' />
          </Transition.Child>

          {/* This element is to trick the browser into centering the modal contents. */}
          <span
            className='hidden sm:inline-block sm:align-middle sm:h-screen'
            aria-hidden='true'
          >
            &#8203;
          </span>
          <Transition.Child
            as={Fragment}
            enter='ease-out duration-300'
            enterFrom='opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95'
            enterTo='opacity-100 translate-y-0 sm:scale-100'
            leave='ease-in duration-200'
            leaveFrom='opacity-100 translate-y-0 sm:scale-100'
            leaveTo='opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95'
          >
            <div className={classNames(
              'inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left shadow-xl transform transition-all sm:my-8 sm:align-middle sm:w-full sm:p-6',
              alertData?.maxwmax ? 'sm:max-w-max' : 'sm:max-w-sm'
            )}
            >
              <div>
                <div className='mx-auto flex items-center justify-center'>
                  {alertData?.iconComponent}
                </div>
                <div className='mt-3 text-center sm:mt-5'>
                  <Dialog.Title
                    as='h3'
                    className='text-lg leading-6 font-medium text-gray-900'
                  >
                    {alertData?.title}
                  </Dialog.Title>
                  <div className='mt-2'>
                    <p className='text-sm text-gray-500'>
                      {alertData?.message}
                    </p>
                  </div>
                </div>
              </div>

              <div>{alertData?.children}</div>

              <div
                className={classNames(
                  alertData?.buttons?.length > 1 &&
                    'sm:grid sm:grid-cols-2 sm:gap-3 sm:grid-flow-row-dense',
                  'mt-5 sm:mt-6'
                )}
              >
                {alertData?.buttons?.map(({ onPress, style, text }, index) => (
                  <button
                    key={index}
                    type='button'
                    onClick={() => {
                      onPress && onPress()
                      setVisible(false)
                    }}
                    className={classNames(
                      index === 0 &&
                        alertData?.buttons?.length > 1 &&
                        'sm:col-start-2',
                      index === 1 && 'mt-3 sm:col-start-1',
                      style === 'cancel'
                        ? 'border-gray-300 bg-white  text-gray-700 hover:bg-gray-50'
                        : 'bg-indigo-600 text-white border-transparent hover:bg-indigo-700',
                      'inline-flex justify-center w-full rounded-md border shadow-sm px-4 py-2 text-base font-medium focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:text-sm sm:mt-0'
                    )}
                  >
                    {text}
                  </button>
                ))}
              </div>
            </div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition.Root>
  )
})

// Modal.propTypes = {
//   title: PropTypes.string.isRequired,
//   message: PropTypes.string.isRequired,
//   buttons: PropTypes.arrayOf(PropTypes.object).isRequired, // TODO defice object containing keys text, onPress, style
//   visible: PropTypes.bool.isRequired,
//   setVisible: PropTypes.func.isRequired,
// };

export default Modal
