class NotificationHolder {
  static notification;
  static setNotification(notification) {
    this.notification = notification;
  }
  static getNotification() {
    return this.notification;
  }
}

export default NotificationHolder;
