class ModalHolder {
  static modal;
  static setModal(modal) {
    this.modal = modal;
  }
  static getModal() {
    return this.modal;
  }
}

export default ModalHolder;
