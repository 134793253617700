const TRANSLATION_LOCATION = "constants.user_types.";

const types = [
  {
    id: "FREEMIUM",
    label: "Freemium",
    description: "zB. Hero Aktion (Hero Mail / Paywall)",
    available: true,
  },
  {
    id: "STANDARD",
    label: "Standard Dashboard Nutzer",
    description:
      "Der Nutzer kann ein Payment im Dashboard selbstständig abschliessen",
    available: true,
  },
  {
    id: "PAYED",
    label: "Dealer",
    description:
      "Nutzer der bereits anderweitig eine Bezahlung vorgenommen hat oder sonstige Deals eingegangen ist",
    available: true,
  },
  {
    id: "APPLICANT",
    label: "Bewerber (B2C)",
    description: "Ein Bewerber anlegen",
    available: false,
  },
];

export const getUserTypes = () => {
  return types;
};
