import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import "./i18n";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
// import i18n (needs to be bundled ;))
import Bugsnag from "@bugsnag/js";
import BugsnagPluginReact from "@bugsnag/plugin-react";
import { BUILD_ENV, NODE_ENV } from "./helpers";
import { localStorage } from "./helpers/local-storage";

Bugsnag.start({
  apiKey: "5f079877995b7d1770be821145c7e79f",
  plugins: [new BugsnagPluginReact()],
  onError: (event) => {
    if (NODE_ENV === "development") return false;

    event.addMetadata("build_env", { env: BUILD_ENV.toUpperCase() });
    event.addMetadata("user_id", { env: localStorage.getItem("user-id") });

    event.errors.forEach(
      (error) =>
        (error.errorMessage = `[${BUILD_ENV.toUpperCase()}] ${
          error.errorMessage
        }`)
    );
  },
});

const ErrorBoundary = Bugsnag.getPlugin("react").createErrorBoundary(React);

ReactDOM.render(
  <React.StrictMode>
    <ErrorBoundary>
      <App />
    </ErrorBoundary>
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
