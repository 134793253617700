import PropTypes from 'prop-types'
import { ExclamationCircleIcon } from '@heroicons/react/solid'

const DEFAULT_CLASSES = 'shadow-sm focus:ring-indigo-500 focus:border-indigo-500 border-gray-300'
const ERROR_CLASSES = 'pr-10 border-red-300 text-red-900 placeholder-red-300 focus:outline-none focus:ring-red-500 focus:border-red-500'

const Input = (
  {
    id,
    label,
    type,
    onChange,
    onKeyDown,
    onBlur,
    value,
    placeholder,
    touched,
    error,
    disabled,
    autoComplete,
    description,
    inlineErrorMessage
  }
) => {
  return (
    <>
      <label htmlFor={id} className='block text-sm font-medium text-gray-700'>
        {label}
      </label>
      <div className='relative mt-2'>
        <input
          id={id}
          type={type}
          name={id}
          onChange={onChange}
          onKeyDown={onKeyDown}
          onBlur={onBlur}
          value={value}
          placeholder={placeholder}
          className={`block w-full rounded-md text-sm ${touched && error ? ERROR_CLASSES : DEFAULT_CLASSES}`}
          style={disabled ? { background: '#eeeeee', cursor: 'auto' } : {}}
          readOnly={disabled}
          disabled={disabled}
          autoComplete={autoComplete}
        />
        {
          touched && error &&
            <div className='absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none'>
              {
              inlineErrorMessage &&
                <p className='mr-1 text-sm text-red-600'>
                  {error}
                </p>
            }
              <ExclamationCircleIcon className='h-5 w-5 text-red-500' aria-hidden='true' />
            </div>
        }
      </div>
      {
        touched && !inlineErrorMessage && error &&
          <p className='mt-2 text-sm text-red-600'>
            {error}
          </p>
      }
      {
        description &&
          <p className='text-gray-500 text-xs mt-2 italic'>
            {description}
          </p>
      }
    </>
  )
}

Input.propTypes = {
  id: PropTypes.string.isRequired,
  label: PropTypes.string,
  type: PropTypes.string,
  onChange: PropTypes.func,
  onKeyDown: PropTypes.func,
  onBlur: PropTypes.func,
  value: PropTypes.string,
  placeholder: PropTypes.string,
  touched: PropTypes.bool,
  error: PropTypes.string,
  disabled: PropTypes.bool,
  autoComplete: PropTypes.string,
  description: PropTypes.string,
  inlineErrorMessage: PropTypes.bool
}

Input.defaultProps = {
  type: 'text',
  label: undefined,
  onChange: undefined,
  onKeyDown: undefined,
  onBlur: undefined,
  value: undefined,
  placeholder: '',
  touched: undefined,
  error: undefined,
  disabled: false,
  autoComplete: undefined,
  description: undefined,
  inlineErrorMessage: false
}

export default Input
