/* This example requires Tailwind CSS v2.0+ */
import PropTypes from 'prop-types'
import { XIcon } from '@heroicons/react/outline'
import { classNames } from '../helpers'

const Announcement = ({ text, onPress, type }) => (
  <div className={classNames('relative', type === 'ERROR' ? 'bg-red-400' : 'bg-primaryBlue')}>
    <div className='max-w-7xl mx-auto py-3 px-3 sm:px-6 lg:px-8'>
      <div className='pr-16 sm:text-center sm:px-16'>
        <p className='font-medium text-white'>
          <span>{text}</span>
        </p>
      </div>
      <div className='absolute inset-y-0 right-0 pt-1 pr-1 flex items-start sm:pt-1 sm:pr-2 sm:items-start'>
        <button
          type='button'
          className='flex p-2 rounded-md hover:bg-indigo-500 focus:outline-none focus:ring-2 focus:ring-white'
        >
          <span className='sr-only'>Dismiss</span>
          <XIcon onClick={onPress} className='h-6 w-6 text-white' aria-hidden='true' />
        </button>
      </div>
    </div>
  </div>
)

Announcement.propTypes = {
  text: PropTypes.string,
  onPress: PropTypes.func,
  type: PropTypes.oneOf(['INFO', 'ERROR'])
}

Announcement.defaultProps = {
  text: '',
  onPress: () => {},
  type: 'INFO'
}

export default Announcement
