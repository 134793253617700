/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const setSpaceComplete = /* GraphQL */ `
  mutation SetSpaceComplete($id: ID) {
    setSpaceComplete(id: $id) {
      id
      owner
      history {
        eventName
        beforeStatus
        statusOption
        afterStatus
        createdAt
        message
        eventFiredBy
      }
      newsletter
      progress
      partner_id
      authorized
      career_id
      partner_user_id
      language
      ref_link
      matching_score_to_space_career
      related_spaces
      person {
        id
        owner
        authorized
        invitation_firstname
        firstname
        invitation_lastname
        lastname
        invitation_email
        email
        user {
          id
          owner
          time_in_app
          type
          role
          invited_from
          authorized
          employee_at_partner
          opens_app
          opens_app_daily
          avg_challenge_rating
          gender
          content_categories
          push_career
          push_jobs
          push_companies
          push_exams
          push_events
          active_space_id
          delete_text
          privacy
          mail_stored
          phone_stored
          last_seen
          createdAt
          updatedAt
          career_right_swipe_rate
          suggested_hero
          hero
          push_token
          pinpoint_endpoint
          education_level
          work_experience
          remote
        }
        space {
          id
          owner
          newsletter
          progress
          partner_id
          authorized
          career_id
          partner_user_id
          language
          ref_link
          matching_score_to_space_career
          related_spaces
          invitation_id
          callback_url
          expiresAt
          created_with_service
          invitation_reminder_at
          createdAt
          updatedAt
        }
        createdAt
        updatedAt
        personUserId
        personSpaceId
      }
      invitation_id
      callback_url
      expiresAt
      created_with_service
      invitation_reminder_at
      createdAt
      updatedAt
    }
  }
`;
export const createSystem = /* GraphQL */ `
  mutation CreateSystem(
    $input: CreateSystemInput!
    $condition: ModelSystemConditionInput
  ) {
    createSystem(input: $input, condition: $condition) {
      id
      type
      data
      createdAt
      updatedAt
    }
  }
`;
export const updateSystem = /* GraphQL */ `
  mutation UpdateSystem(
    $input: UpdateSystemInput!
    $condition: ModelSystemConditionInput
  ) {
    updateSystem(input: $input, condition: $condition) {
      id
      type
      data
      createdAt
      updatedAt
    }
  }
`;
export const deleteSystem = /* GraphQL */ `
  mutation DeleteSystem(
    $input: DeleteSystemInput!
    $condition: ModelSystemConditionInput
  ) {
    deleteSystem(input: $input, condition: $condition) {
      id
      type
      data
      createdAt
      updatedAt
    }
  }
`;
export const createLeads = /* GraphQL */ `
  mutation CreateLeads(
    $input: CreateLeadsInput!
    $condition: ModelLeadsConditionInput
  ) {
    createLeads(input: $input, condition: $condition) {
      id
      data
      email
      phone
      status
      lead_source
      partner_id
      campaign_url
      createdAt
      updatedAt
    }
  }
`;
export const updateLeads = /* GraphQL */ `
  mutation UpdateLeads(
    $input: UpdateLeadsInput!
    $condition: ModelLeadsConditionInput
  ) {
    updateLeads(input: $input, condition: $condition) {
      id
      data
      email
      phone
      status
      lead_source
      partner_id
      campaign_url
      createdAt
      updatedAt
    }
  }
`;
export const deleteLeads = /* GraphQL */ `
  mutation DeleteLeads(
    $input: DeleteLeadsInput!
    $condition: ModelLeadsConditionInput
  ) {
    deleteLeads(input: $input, condition: $condition) {
      id
      data
      email
      phone
      status
      lead_source
      partner_id
      campaign_url
      createdAt
      updatedAt
    }
  }
`;
export const createForm = /* GraphQL */ `
  mutation CreateForm(
    $input: CreateFormInput!
    $condition: ModelFormConditionInput
  ) {
    createForm(input: $input, condition: $condition) {
      id
      data
      email
      phone
      status
      form_source
      campaign_url
      createdAt
      updatedAt
    }
  }
`;
export const updateForm = /* GraphQL */ `
  mutation UpdateForm(
    $input: UpdateFormInput!
    $condition: ModelFormConditionInput
  ) {
    updateForm(input: $input, condition: $condition) {
      id
      data
      email
      phone
      status
      form_source
      campaign_url
      createdAt
      updatedAt
    }
  }
`;
export const deleteForm = /* GraphQL */ `
  mutation DeleteForm(
    $input: DeleteFormInput!
    $condition: ModelFormConditionInput
  ) {
    deleteForm(input: $input, condition: $condition) {
      id
      data
      email
      phone
      status
      form_source
      campaign_url
      createdAt
      updatedAt
    }
  }
`;
export const createUser = /* GraphQL */ `
  mutation CreateUser(
    $input: CreateUserInput!
    $condition: ModelUserConditionInput
  ) {
    createUser(input: $input, condition: $condition) {
      id
      owner
      time_in_app
      type
      role
      invited_from
      authorized
      employee_at_partner
      partner {
        id
        name
        accesses {
          nextToken
        }
        display_name
        authorized
        career_site_url
        user_type
        restricted
        lead_id
        payment_type
        external_payment_meta {
          external_payment_user_id
          external_payment_service
        }
        planned_new_hires_per_year
        app_settings {
          components
          challenges
          custom_apply_mail_template
          usedAlgorithm
          hello_text
          custom_data_policy
        }
        logo
        logo_ratio
        logo_alt
        logo_alt_ratio
        createdAt
        updatedAt
      }
      person {
        id
        owner
        authorized
        invitation_firstname
        firstname
        invitation_lastname
        lastname
        invitation_email
        email
        user {
          id
          owner
          time_in_app
          type
          role
          invited_from
          authorized
          employee_at_partner
          opens_app
          opens_app_daily
          avg_challenge_rating
          gender
          content_categories
          push_career
          push_jobs
          push_companies
          push_exams
          push_events
          active_space_id
          delete_text
          privacy
          mail_stored
          phone_stored
          last_seen
          createdAt
          updatedAt
          career_right_swipe_rate
          suggested_hero
          hero
          push_token
          pinpoint_endpoint
          education_level
          work_experience
          remote
        }
        space {
          id
          owner
          newsletter
          progress
          partner_id
          authorized
          career_id
          partner_user_id
          language
          ref_link
          matching_score_to_space_career
          related_spaces
          invitation_id
          callback_url
          expiresAt
          created_with_service
          invitation_reminder_at
          createdAt
          updatedAt
        }
        createdAt
        updatedAt
        personUserId
        personSpaceId
      }
      opens_app
      opens_app_daily
      avg_challenge_rating
      gender
      content_categories
      push_career
      push_jobs
      push_companies
      push_exams
      push_events
      active_space_id
      delete_text
      privacy
      mail_stored
      phone_stored
      last_seen
      createdAt
      updatedAt
      career_right_swipe_rate
      suggested_hero
      hero
      location {
        lat
        lon
        city
      }
      percentiles {
        BIG_FIVE_O_score
        BIG_FIVE_C_score
        BIG_FIVE_o_score
        BIG_FIVE_c_score
        BIG_FIVE_e_score
        BIG_FIVE_a_score
        BIG_FIVE_n_score
        BIG_FIVE_elevation
        RIASEC_IMAGES_elevation
        RIASEC_IMAGES_r_score
        RIASEC_IMAGES_i_score
        RIASEC_IMAGES_a_score
        RIASEC_IMAGES_s_score
        RIASEC_IMAGES_e_score
        RIASEC_IMAGES_c_score
        HEROS_JOURNEY_score_coopcomp
        HEROS_JOURNEY_score_orderchaos
        TRUST_score
        TROLLEY_score
        SPEEDACC_score
        FLYINGDUTCH_score
        RESPDECISION_score
        PATH_FINDER_score
        FLANKER_score
        EMOTIONS_GAME_score
        ANALOGICAL_REASONING_score
        TOWER_OF_LONDON_score
        DIGIT_SPAN_score
        NBACK_score
        FLANKER_avg_speed
      }
      reach_requests {
        items {
          id
          owner
          seen
          clicked
          authorized
          email
          career_url
          level
          ttl
          career_id
          partner_id
          createdAt
          updatedAt
        }
        nextToken
      }
      reach_settings {
        searching_type
        searching_distance
      }
      push_token
      pinpoint_endpoint
      education_level
      work_experience
      remote
    }
  }
`;
export const updateUser = /* GraphQL */ `
  mutation UpdateUser(
    $input: UpdateUserInput!
    $condition: ModelUserConditionInput
  ) {
    updateUser(input: $input, condition: $condition) {
      id
      owner
      time_in_app
      type
      role
      invited_from
      authorized
      employee_at_partner
      partner {
        id
        name
        accesses {
          nextToken
        }
        display_name
        authorized
        career_site_url
        user_type
        restricted
        lead_id
        payment_type
        external_payment_meta {
          external_payment_user_id
          external_payment_service
        }
        planned_new_hires_per_year
        app_settings {
          components
          challenges
          custom_apply_mail_template
          usedAlgorithm
          hello_text
          custom_data_policy
        }
        logo
        logo_ratio
        logo_alt
        logo_alt_ratio
        createdAt
        updatedAt
      }
      person {
        id
        owner
        authorized
        invitation_firstname
        firstname
        invitation_lastname
        lastname
        invitation_email
        email
        user {
          id
          owner
          time_in_app
          type
          role
          invited_from
          authorized
          employee_at_partner
          opens_app
          opens_app_daily
          avg_challenge_rating
          gender
          content_categories
          push_career
          push_jobs
          push_companies
          push_exams
          push_events
          active_space_id
          delete_text
          privacy
          mail_stored
          phone_stored
          last_seen
          createdAt
          updatedAt
          career_right_swipe_rate
          suggested_hero
          hero
          push_token
          pinpoint_endpoint
          education_level
          work_experience
          remote
        }
        space {
          id
          owner
          newsletter
          progress
          partner_id
          authorized
          career_id
          partner_user_id
          language
          ref_link
          matching_score_to_space_career
          related_spaces
          invitation_id
          callback_url
          expiresAt
          created_with_service
          invitation_reminder_at
          createdAt
          updatedAt
        }
        createdAt
        updatedAt
        personUserId
        personSpaceId
      }
      opens_app
      opens_app_daily
      avg_challenge_rating
      gender
      content_categories
      push_career
      push_jobs
      push_companies
      push_exams
      push_events
      active_space_id
      delete_text
      privacy
      mail_stored
      phone_stored
      last_seen
      createdAt
      updatedAt
      career_right_swipe_rate
      suggested_hero
      hero
      location {
        lat
        lon
        city
      }
      percentiles {
        BIG_FIVE_O_score
        BIG_FIVE_C_score
        BIG_FIVE_o_score
        BIG_FIVE_c_score
        BIG_FIVE_e_score
        BIG_FIVE_a_score
        BIG_FIVE_n_score
        BIG_FIVE_elevation
        RIASEC_IMAGES_elevation
        RIASEC_IMAGES_r_score
        RIASEC_IMAGES_i_score
        RIASEC_IMAGES_a_score
        RIASEC_IMAGES_s_score
        RIASEC_IMAGES_e_score
        RIASEC_IMAGES_c_score
        HEROS_JOURNEY_score_coopcomp
        HEROS_JOURNEY_score_orderchaos
        TRUST_score
        TROLLEY_score
        SPEEDACC_score
        FLYINGDUTCH_score
        RESPDECISION_score
        PATH_FINDER_score
        FLANKER_score
        EMOTIONS_GAME_score
        ANALOGICAL_REASONING_score
        TOWER_OF_LONDON_score
        DIGIT_SPAN_score
        NBACK_score
        FLANKER_avg_speed
      }
      reach_requests {
        items {
          id
          owner
          seen
          clicked
          authorized
          email
          career_url
          level
          ttl
          career_id
          partner_id
          createdAt
          updatedAt
        }
        nextToken
      }
      reach_settings {
        searching_type
        searching_distance
      }
      push_token
      pinpoint_endpoint
      education_level
      work_experience
      remote
    }
  }
`;
export const deleteUser = /* GraphQL */ `
  mutation DeleteUser(
    $input: DeleteUserInput!
    $condition: ModelUserConditionInput
  ) {
    deleteUser(input: $input, condition: $condition) {
      id
      owner
      time_in_app
      type
      role
      invited_from
      authorized
      employee_at_partner
      partner {
        id
        name
        accesses {
          nextToken
        }
        display_name
        authorized
        career_site_url
        user_type
        restricted
        lead_id
        payment_type
        external_payment_meta {
          external_payment_user_id
          external_payment_service
        }
        planned_new_hires_per_year
        app_settings {
          components
          challenges
          custom_apply_mail_template
          usedAlgorithm
          hello_text
          custom_data_policy
        }
        logo
        logo_ratio
        logo_alt
        logo_alt_ratio
        createdAt
        updatedAt
      }
      person {
        id
        owner
        authorized
        invitation_firstname
        firstname
        invitation_lastname
        lastname
        invitation_email
        email
        user {
          id
          owner
          time_in_app
          type
          role
          invited_from
          authorized
          employee_at_partner
          opens_app
          opens_app_daily
          avg_challenge_rating
          gender
          content_categories
          push_career
          push_jobs
          push_companies
          push_exams
          push_events
          active_space_id
          delete_text
          privacy
          mail_stored
          phone_stored
          last_seen
          createdAt
          updatedAt
          career_right_swipe_rate
          suggested_hero
          hero
          push_token
          pinpoint_endpoint
          education_level
          work_experience
          remote
        }
        space {
          id
          owner
          newsletter
          progress
          partner_id
          authorized
          career_id
          partner_user_id
          language
          ref_link
          matching_score_to_space_career
          related_spaces
          invitation_id
          callback_url
          expiresAt
          created_with_service
          invitation_reminder_at
          createdAt
          updatedAt
        }
        createdAt
        updatedAt
        personUserId
        personSpaceId
      }
      opens_app
      opens_app_daily
      avg_challenge_rating
      gender
      content_categories
      push_career
      push_jobs
      push_companies
      push_exams
      push_events
      active_space_id
      delete_text
      privacy
      mail_stored
      phone_stored
      last_seen
      createdAt
      updatedAt
      career_right_swipe_rate
      suggested_hero
      hero
      location {
        lat
        lon
        city
      }
      percentiles {
        BIG_FIVE_O_score
        BIG_FIVE_C_score
        BIG_FIVE_o_score
        BIG_FIVE_c_score
        BIG_FIVE_e_score
        BIG_FIVE_a_score
        BIG_FIVE_n_score
        BIG_FIVE_elevation
        RIASEC_IMAGES_elevation
        RIASEC_IMAGES_r_score
        RIASEC_IMAGES_i_score
        RIASEC_IMAGES_a_score
        RIASEC_IMAGES_s_score
        RIASEC_IMAGES_e_score
        RIASEC_IMAGES_c_score
        HEROS_JOURNEY_score_coopcomp
        HEROS_JOURNEY_score_orderchaos
        TRUST_score
        TROLLEY_score
        SPEEDACC_score
        FLYINGDUTCH_score
        RESPDECISION_score
        PATH_FINDER_score
        FLANKER_score
        EMOTIONS_GAME_score
        ANALOGICAL_REASONING_score
        TOWER_OF_LONDON_score
        DIGIT_SPAN_score
        NBACK_score
        FLANKER_avg_speed
      }
      reach_requests {
        items {
          id
          owner
          seen
          clicked
          authorized
          email
          career_url
          level
          ttl
          career_id
          partner_id
          createdAt
          updatedAt
        }
        nextToken
      }
      reach_settings {
        searching_type
        searching_distance
      }
      push_token
      pinpoint_endpoint
      education_level
      work_experience
      remote
    }
  }
`;
export const createPayment = /* GraphQL */ `
  mutation CreatePayment(
    $input: CreatePaymentInput!
    $condition: ModelPaymentConditionInput
  ) {
    createPayment(input: $input, condition: $condition) {
      id
      partner_id
      authorized
      invoice_url
      product
      expires_at
      start_at
      price
      freemium
      payment_service
      external_payment_id
      payment_meta
      spaces_count
      career_count
      comment
      hirings_count
      owner
      createdAt
      updatedAt
    }
  }
`;
export const updatePayment = /* GraphQL */ `
  mutation UpdatePayment(
    $input: UpdatePaymentInput!
    $condition: ModelPaymentConditionInput
  ) {
    updatePayment(input: $input, condition: $condition) {
      id
      partner_id
      authorized
      invoice_url
      product
      expires_at
      start_at
      price
      freemium
      payment_service
      external_payment_id
      payment_meta
      spaces_count
      career_count
      comment
      hirings_count
      owner
      createdAt
      updatedAt
    }
  }
`;
export const deletePayment = /* GraphQL */ `
  mutation DeletePayment(
    $input: DeletePaymentInput!
    $condition: ModelPaymentConditionInput
  ) {
    deletePayment(input: $input, condition: $condition) {
      id
      partner_id
      authorized
      invoice_url
      product
      expires_at
      start_at
      price
      freemium
      payment_service
      external_payment_id
      payment_meta
      spaces_count
      career_count
      comment
      hirings_count
      owner
      createdAt
      updatedAt
    }
  }
`;
export const createSpace = /* GraphQL */ `
  mutation CreateSpace(
    $input: CreateSpaceInput!
    $condition: ModelSpaceConditionInput
  ) {
    createSpace(input: $input, condition: $condition) {
      id
      owner
      history {
        eventName
        beforeStatus
        statusOption
        afterStatus
        createdAt
        message
        eventFiredBy
      }
      newsletter
      progress
      partner_id
      authorized
      career_id
      partner_user_id
      language
      ref_link
      matching_score_to_space_career
      related_spaces
      person {
        id
        owner
        authorized
        invitation_firstname
        firstname
        invitation_lastname
        lastname
        invitation_email
        email
        user {
          id
          owner
          time_in_app
          type
          role
          invited_from
          authorized
          employee_at_partner
          opens_app
          opens_app_daily
          avg_challenge_rating
          gender
          content_categories
          push_career
          push_jobs
          push_companies
          push_exams
          push_events
          active_space_id
          delete_text
          privacy
          mail_stored
          phone_stored
          last_seen
          createdAt
          updatedAt
          career_right_swipe_rate
          suggested_hero
          hero
          push_token
          pinpoint_endpoint
          education_level
          work_experience
          remote
        }
        space {
          id
          owner
          newsletter
          progress
          partner_id
          authorized
          career_id
          partner_user_id
          language
          ref_link
          matching_score_to_space_career
          related_spaces
          invitation_id
          callback_url
          expiresAt
          created_with_service
          invitation_reminder_at
          createdAt
          updatedAt
        }
        createdAt
        updatedAt
        personUserId
        personSpaceId
      }
      invitation_id
      callback_url
      expiresAt
      created_with_service
      invitation_reminder_at
      createdAt
      updatedAt
    }
  }
`;
export const updateSpace = /* GraphQL */ `
  mutation UpdateSpace(
    $input: UpdateSpaceInput!
    $condition: ModelSpaceConditionInput
  ) {
    updateSpace(input: $input, condition: $condition) {
      id
      owner
      history {
        eventName
        beforeStatus
        statusOption
        afterStatus
        createdAt
        message
        eventFiredBy
      }
      newsletter
      progress
      partner_id
      authorized
      career_id
      partner_user_id
      language
      ref_link
      matching_score_to_space_career
      related_spaces
      person {
        id
        owner
        authorized
        invitation_firstname
        firstname
        invitation_lastname
        lastname
        invitation_email
        email
        user {
          id
          owner
          time_in_app
          type
          role
          invited_from
          authorized
          employee_at_partner
          opens_app
          opens_app_daily
          avg_challenge_rating
          gender
          content_categories
          push_career
          push_jobs
          push_companies
          push_exams
          push_events
          active_space_id
          delete_text
          privacy
          mail_stored
          phone_stored
          last_seen
          createdAt
          updatedAt
          career_right_swipe_rate
          suggested_hero
          hero
          push_token
          pinpoint_endpoint
          education_level
          work_experience
          remote
        }
        space {
          id
          owner
          newsletter
          progress
          partner_id
          authorized
          career_id
          partner_user_id
          language
          ref_link
          matching_score_to_space_career
          related_spaces
          invitation_id
          callback_url
          expiresAt
          created_with_service
          invitation_reminder_at
          createdAt
          updatedAt
        }
        createdAt
        updatedAt
        personUserId
        personSpaceId
      }
      invitation_id
      callback_url
      expiresAt
      created_with_service
      invitation_reminder_at
      createdAt
      updatedAt
    }
  }
`;
export const deleteSpace = /* GraphQL */ `
  mutation DeleteSpace(
    $input: DeleteSpaceInput!
    $condition: ModelSpaceConditionInput
  ) {
    deleteSpace(input: $input, condition: $condition) {
      id
      owner
      history {
        eventName
        beforeStatus
        statusOption
        afterStatus
        createdAt
        message
        eventFiredBy
      }
      newsletter
      progress
      partner_id
      authorized
      career_id
      partner_user_id
      language
      ref_link
      matching_score_to_space_career
      related_spaces
      person {
        id
        owner
        authorized
        invitation_firstname
        firstname
        invitation_lastname
        lastname
        invitation_email
        email
        user {
          id
          owner
          time_in_app
          type
          role
          invited_from
          authorized
          employee_at_partner
          opens_app
          opens_app_daily
          avg_challenge_rating
          gender
          content_categories
          push_career
          push_jobs
          push_companies
          push_exams
          push_events
          active_space_id
          delete_text
          privacy
          mail_stored
          phone_stored
          last_seen
          createdAt
          updatedAt
          career_right_swipe_rate
          suggested_hero
          hero
          push_token
          pinpoint_endpoint
          education_level
          work_experience
          remote
        }
        space {
          id
          owner
          newsletter
          progress
          partner_id
          authorized
          career_id
          partner_user_id
          language
          ref_link
          matching_score_to_space_career
          related_spaces
          invitation_id
          callback_url
          expiresAt
          created_with_service
          invitation_reminder_at
          createdAt
          updatedAt
        }
        createdAt
        updatedAt
        personUserId
        personSpaceId
      }
      invitation_id
      callback_url
      expiresAt
      created_with_service
      invitation_reminder_at
      createdAt
      updatedAt
    }
  }
`;
export const createPerson = /* GraphQL */ `
  mutation CreatePerson(
    $input: CreatePersonInput!
    $condition: ModelPersonConditionInput
  ) {
    createPerson(input: $input, condition: $condition) {
      id
      owner
      authorized
      invitation_firstname
      firstname
      invitation_lastname
      lastname
      invitation_email
      email
      user {
        id
        owner
        time_in_app
        type
        role
        invited_from
        authorized
        employee_at_partner
        partner {
          id
          name
          display_name
          authorized
          career_site_url
          user_type
          restricted
          lead_id
          payment_type
          planned_new_hires_per_year
          logo
          logo_ratio
          logo_alt
          logo_alt_ratio
          createdAt
          updatedAt
        }
        person {
          id
          owner
          authorized
          invitation_firstname
          firstname
          invitation_lastname
          lastname
          invitation_email
          email
          createdAt
          updatedAt
          personUserId
          personSpaceId
        }
        opens_app
        opens_app_daily
        avg_challenge_rating
        gender
        content_categories
        push_career
        push_jobs
        push_companies
        push_exams
        push_events
        active_space_id
        delete_text
        privacy
        mail_stored
        phone_stored
        last_seen
        createdAt
        updatedAt
        career_right_swipe_rate
        suggested_hero
        hero
        location {
          lat
          lon
          city
        }
        percentiles {
          BIG_FIVE_O_score
          BIG_FIVE_C_score
          BIG_FIVE_o_score
          BIG_FIVE_c_score
          BIG_FIVE_e_score
          BIG_FIVE_a_score
          BIG_FIVE_n_score
          BIG_FIVE_elevation
          RIASEC_IMAGES_elevation
          RIASEC_IMAGES_r_score
          RIASEC_IMAGES_i_score
          RIASEC_IMAGES_a_score
          RIASEC_IMAGES_s_score
          RIASEC_IMAGES_e_score
          RIASEC_IMAGES_c_score
          HEROS_JOURNEY_score_coopcomp
          HEROS_JOURNEY_score_orderchaos
          TRUST_score
          TROLLEY_score
          SPEEDACC_score
          FLYINGDUTCH_score
          RESPDECISION_score
          PATH_FINDER_score
          FLANKER_score
          EMOTIONS_GAME_score
          ANALOGICAL_REASONING_score
          TOWER_OF_LONDON_score
          DIGIT_SPAN_score
          NBACK_score
          FLANKER_avg_speed
        }
        reach_requests {
          nextToken
        }
        reach_settings {
          searching_type
          searching_distance
        }
        push_token
        pinpoint_endpoint
        education_level
        work_experience
        remote
      }
      space {
        id
        owner
        history {
          eventName
          beforeStatus
          statusOption
          afterStatus
          createdAt
          message
          eventFiredBy
        }
        newsletter
        progress
        partner_id
        authorized
        career_id
        partner_user_id
        language
        ref_link
        matching_score_to_space_career
        related_spaces
        person {
          id
          owner
          authorized
          invitation_firstname
          firstname
          invitation_lastname
          lastname
          invitation_email
          email
          createdAt
          updatedAt
          personUserId
          personSpaceId
        }
        invitation_id
        callback_url
        expiresAt
        created_with_service
        invitation_reminder_at
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
      personUserId
      personSpaceId
    }
  }
`;
export const updatePerson = /* GraphQL */ `
  mutation UpdatePerson(
    $input: UpdatePersonInput!
    $condition: ModelPersonConditionInput
  ) {
    updatePerson(input: $input, condition: $condition) {
      id
      owner
      authorized
      invitation_firstname
      firstname
      invitation_lastname
      lastname
      invitation_email
      email
      user {
        id
        owner
        time_in_app
        type
        role
        invited_from
        authorized
        employee_at_partner
        partner {
          id
          name
          display_name
          authorized
          career_site_url
          user_type
          restricted
          lead_id
          payment_type
          planned_new_hires_per_year
          logo
          logo_ratio
          logo_alt
          logo_alt_ratio
          createdAt
          updatedAt
        }
        person {
          id
          owner
          authorized
          invitation_firstname
          firstname
          invitation_lastname
          lastname
          invitation_email
          email
          createdAt
          updatedAt
          personUserId
          personSpaceId
        }
        opens_app
        opens_app_daily
        avg_challenge_rating
        gender
        content_categories
        push_career
        push_jobs
        push_companies
        push_exams
        push_events
        active_space_id
        delete_text
        privacy
        mail_stored
        phone_stored
        last_seen
        createdAt
        updatedAt
        career_right_swipe_rate
        suggested_hero
        hero
        location {
          lat
          lon
          city
        }
        percentiles {
          BIG_FIVE_O_score
          BIG_FIVE_C_score
          BIG_FIVE_o_score
          BIG_FIVE_c_score
          BIG_FIVE_e_score
          BIG_FIVE_a_score
          BIG_FIVE_n_score
          BIG_FIVE_elevation
          RIASEC_IMAGES_elevation
          RIASEC_IMAGES_r_score
          RIASEC_IMAGES_i_score
          RIASEC_IMAGES_a_score
          RIASEC_IMAGES_s_score
          RIASEC_IMAGES_e_score
          RIASEC_IMAGES_c_score
          HEROS_JOURNEY_score_coopcomp
          HEROS_JOURNEY_score_orderchaos
          TRUST_score
          TROLLEY_score
          SPEEDACC_score
          FLYINGDUTCH_score
          RESPDECISION_score
          PATH_FINDER_score
          FLANKER_score
          EMOTIONS_GAME_score
          ANALOGICAL_REASONING_score
          TOWER_OF_LONDON_score
          DIGIT_SPAN_score
          NBACK_score
          FLANKER_avg_speed
        }
        reach_requests {
          nextToken
        }
        reach_settings {
          searching_type
          searching_distance
        }
        push_token
        pinpoint_endpoint
        education_level
        work_experience
        remote
      }
      space {
        id
        owner
        history {
          eventName
          beforeStatus
          statusOption
          afterStatus
          createdAt
          message
          eventFiredBy
        }
        newsletter
        progress
        partner_id
        authorized
        career_id
        partner_user_id
        language
        ref_link
        matching_score_to_space_career
        related_spaces
        person {
          id
          owner
          authorized
          invitation_firstname
          firstname
          invitation_lastname
          lastname
          invitation_email
          email
          createdAt
          updatedAt
          personUserId
          personSpaceId
        }
        invitation_id
        callback_url
        expiresAt
        created_with_service
        invitation_reminder_at
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
      personUserId
      personSpaceId
    }
  }
`;
export const deletePerson = /* GraphQL */ `
  mutation DeletePerson(
    $input: DeletePersonInput!
    $condition: ModelPersonConditionInput
  ) {
    deletePerson(input: $input, condition: $condition) {
      id
      owner
      authorized
      invitation_firstname
      firstname
      invitation_lastname
      lastname
      invitation_email
      email
      user {
        id
        owner
        time_in_app
        type
        role
        invited_from
        authorized
        employee_at_partner
        partner {
          id
          name
          display_name
          authorized
          career_site_url
          user_type
          restricted
          lead_id
          payment_type
          planned_new_hires_per_year
          logo
          logo_ratio
          logo_alt
          logo_alt_ratio
          createdAt
          updatedAt
        }
        person {
          id
          owner
          authorized
          invitation_firstname
          firstname
          invitation_lastname
          lastname
          invitation_email
          email
          createdAt
          updatedAt
          personUserId
          personSpaceId
        }
        opens_app
        opens_app_daily
        avg_challenge_rating
        gender
        content_categories
        push_career
        push_jobs
        push_companies
        push_exams
        push_events
        active_space_id
        delete_text
        privacy
        mail_stored
        phone_stored
        last_seen
        createdAt
        updatedAt
        career_right_swipe_rate
        suggested_hero
        hero
        location {
          lat
          lon
          city
        }
        percentiles {
          BIG_FIVE_O_score
          BIG_FIVE_C_score
          BIG_FIVE_o_score
          BIG_FIVE_c_score
          BIG_FIVE_e_score
          BIG_FIVE_a_score
          BIG_FIVE_n_score
          BIG_FIVE_elevation
          RIASEC_IMAGES_elevation
          RIASEC_IMAGES_r_score
          RIASEC_IMAGES_i_score
          RIASEC_IMAGES_a_score
          RIASEC_IMAGES_s_score
          RIASEC_IMAGES_e_score
          RIASEC_IMAGES_c_score
          HEROS_JOURNEY_score_coopcomp
          HEROS_JOURNEY_score_orderchaos
          TRUST_score
          TROLLEY_score
          SPEEDACC_score
          FLYINGDUTCH_score
          RESPDECISION_score
          PATH_FINDER_score
          FLANKER_score
          EMOTIONS_GAME_score
          ANALOGICAL_REASONING_score
          TOWER_OF_LONDON_score
          DIGIT_SPAN_score
          NBACK_score
          FLANKER_avg_speed
        }
        reach_requests {
          nextToken
        }
        reach_settings {
          searching_type
          searching_distance
        }
        push_token
        pinpoint_endpoint
        education_level
        work_experience
        remote
      }
      space {
        id
        owner
        history {
          eventName
          beforeStatus
          statusOption
          afterStatus
          createdAt
          message
          eventFiredBy
        }
        newsletter
        progress
        partner_id
        authorized
        career_id
        partner_user_id
        language
        ref_link
        matching_score_to_space_career
        related_spaces
        person {
          id
          owner
          authorized
          invitation_firstname
          firstname
          invitation_lastname
          lastname
          invitation_email
          email
          createdAt
          updatedAt
          personUserId
          personSpaceId
        }
        invitation_id
        callback_url
        expiresAt
        created_with_service
        invitation_reminder_at
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
      personUserId
      personSpaceId
    }
  }
`;
export const createReachRequest = /* GraphQL */ `
  mutation CreateReachRequest(
    $input: CreateReachRequestInput!
    $condition: ModelReachRequestConditionInput
  ) {
    createReachRequest(input: $input, condition: $condition) {
      id
      owner
      seen
      clicked
      authorized
      email
      career_url
      level
      ttl
      career_id
      career {
        id
        name
        photo_url
        title
        status
        archived
        history {
          eventName
          before
          after
          createdAt
          message
          eventFiredBy
        }
        category
        use_in {
          product
          active
          expiresAt
          createdAt
          updatedAt
        }
        partner_id
        authorized
        partner_name
        external_link
        related_career
        R
        I
        A
        S
        E
        C
        BW0
        BW1
        BW2
        BW3
        is_cluster
        is_active
        app_settings {
          components
          challenges
          custom_apply_mail_template
          usedAlgorithm
          hello_text
          custom_data_policy
        }
        career_cluster_url
        forced_dimension
        career_analyse
        createdAt
        updatedAt
      }
      partner_id
      partner {
        id
        name
        accesses {
          nextToken
        }
        display_name
        authorized
        career_site_url
        user_type
        restricted
        lead_id
        payment_type
        external_payment_meta {
          external_payment_user_id
          external_payment_service
        }
        planned_new_hires_per_year
        app_settings {
          components
          challenges
          custom_apply_mail_template
          usedAlgorithm
          hello_text
          custom_data_policy
        }
        logo
        logo_ratio
        logo_alt
        logo_alt_ratio
        createdAt
        updatedAt
      }
      user {
        id
        owner
        time_in_app
        type
        role
        invited_from
        authorized
        employee_at_partner
        partner {
          id
          name
          display_name
          authorized
          career_site_url
          user_type
          restricted
          lead_id
          payment_type
          planned_new_hires_per_year
          logo
          logo_ratio
          logo_alt
          logo_alt_ratio
          createdAt
          updatedAt
        }
        person {
          id
          owner
          authorized
          invitation_firstname
          firstname
          invitation_lastname
          lastname
          invitation_email
          email
          createdAt
          updatedAt
          personUserId
          personSpaceId
        }
        opens_app
        opens_app_daily
        avg_challenge_rating
        gender
        content_categories
        push_career
        push_jobs
        push_companies
        push_exams
        push_events
        active_space_id
        delete_text
        privacy
        mail_stored
        phone_stored
        last_seen
        createdAt
        updatedAt
        career_right_swipe_rate
        suggested_hero
        hero
        location {
          lat
          lon
          city
        }
        percentiles {
          BIG_FIVE_O_score
          BIG_FIVE_C_score
          BIG_FIVE_o_score
          BIG_FIVE_c_score
          BIG_FIVE_e_score
          BIG_FIVE_a_score
          BIG_FIVE_n_score
          BIG_FIVE_elevation
          RIASEC_IMAGES_elevation
          RIASEC_IMAGES_r_score
          RIASEC_IMAGES_i_score
          RIASEC_IMAGES_a_score
          RIASEC_IMAGES_s_score
          RIASEC_IMAGES_e_score
          RIASEC_IMAGES_c_score
          HEROS_JOURNEY_score_coopcomp
          HEROS_JOURNEY_score_orderchaos
          TRUST_score
          TROLLEY_score
          SPEEDACC_score
          FLYINGDUTCH_score
          RESPDECISION_score
          PATH_FINDER_score
          FLANKER_score
          EMOTIONS_GAME_score
          ANALOGICAL_REASONING_score
          TOWER_OF_LONDON_score
          DIGIT_SPAN_score
          NBACK_score
          FLANKER_avg_speed
        }
        reach_requests {
          nextToken
        }
        reach_settings {
          searching_type
          searching_distance
        }
        push_token
        pinpoint_endpoint
        education_level
        work_experience
        remote
      }
      createdAt
      updatedAt
    }
  }
`;
export const updateReachRequest = /* GraphQL */ `
  mutation UpdateReachRequest(
    $input: UpdateReachRequestInput!
    $condition: ModelReachRequestConditionInput
  ) {
    updateReachRequest(input: $input, condition: $condition) {
      id
      owner
      seen
      clicked
      authorized
      email
      career_url
      level
      ttl
      career_id
      career {
        id
        name
        photo_url
        title
        status
        archived
        history {
          eventName
          before
          after
          createdAt
          message
          eventFiredBy
        }
        category
        use_in {
          product
          active
          expiresAt
          createdAt
          updatedAt
        }
        partner_id
        authorized
        partner_name
        external_link
        related_career
        R
        I
        A
        S
        E
        C
        BW0
        BW1
        BW2
        BW3
        is_cluster
        is_active
        app_settings {
          components
          challenges
          custom_apply_mail_template
          usedAlgorithm
          hello_text
          custom_data_policy
        }
        career_cluster_url
        forced_dimension
        career_analyse
        createdAt
        updatedAt
      }
      partner_id
      partner {
        id
        name
        accesses {
          nextToken
        }
        display_name
        authorized
        career_site_url
        user_type
        restricted
        lead_id
        payment_type
        external_payment_meta {
          external_payment_user_id
          external_payment_service
        }
        planned_new_hires_per_year
        app_settings {
          components
          challenges
          custom_apply_mail_template
          usedAlgorithm
          hello_text
          custom_data_policy
        }
        logo
        logo_ratio
        logo_alt
        logo_alt_ratio
        createdAt
        updatedAt
      }
      user {
        id
        owner
        time_in_app
        type
        role
        invited_from
        authorized
        employee_at_partner
        partner {
          id
          name
          display_name
          authorized
          career_site_url
          user_type
          restricted
          lead_id
          payment_type
          planned_new_hires_per_year
          logo
          logo_ratio
          logo_alt
          logo_alt_ratio
          createdAt
          updatedAt
        }
        person {
          id
          owner
          authorized
          invitation_firstname
          firstname
          invitation_lastname
          lastname
          invitation_email
          email
          createdAt
          updatedAt
          personUserId
          personSpaceId
        }
        opens_app
        opens_app_daily
        avg_challenge_rating
        gender
        content_categories
        push_career
        push_jobs
        push_companies
        push_exams
        push_events
        active_space_id
        delete_text
        privacy
        mail_stored
        phone_stored
        last_seen
        createdAt
        updatedAt
        career_right_swipe_rate
        suggested_hero
        hero
        location {
          lat
          lon
          city
        }
        percentiles {
          BIG_FIVE_O_score
          BIG_FIVE_C_score
          BIG_FIVE_o_score
          BIG_FIVE_c_score
          BIG_FIVE_e_score
          BIG_FIVE_a_score
          BIG_FIVE_n_score
          BIG_FIVE_elevation
          RIASEC_IMAGES_elevation
          RIASEC_IMAGES_r_score
          RIASEC_IMAGES_i_score
          RIASEC_IMAGES_a_score
          RIASEC_IMAGES_s_score
          RIASEC_IMAGES_e_score
          RIASEC_IMAGES_c_score
          HEROS_JOURNEY_score_coopcomp
          HEROS_JOURNEY_score_orderchaos
          TRUST_score
          TROLLEY_score
          SPEEDACC_score
          FLYINGDUTCH_score
          RESPDECISION_score
          PATH_FINDER_score
          FLANKER_score
          EMOTIONS_GAME_score
          ANALOGICAL_REASONING_score
          TOWER_OF_LONDON_score
          DIGIT_SPAN_score
          NBACK_score
          FLANKER_avg_speed
        }
        reach_requests {
          nextToken
        }
        reach_settings {
          searching_type
          searching_distance
        }
        push_token
        pinpoint_endpoint
        education_level
        work_experience
        remote
      }
      createdAt
      updatedAt
    }
  }
`;
export const deleteReachRequest = /* GraphQL */ `
  mutation DeleteReachRequest(
    $input: DeleteReachRequestInput!
    $condition: ModelReachRequestConditionInput
  ) {
    deleteReachRequest(input: $input, condition: $condition) {
      id
      owner
      seen
      clicked
      authorized
      email
      career_url
      level
      ttl
      career_id
      career {
        id
        name
        photo_url
        title
        status
        archived
        history {
          eventName
          before
          after
          createdAt
          message
          eventFiredBy
        }
        category
        use_in {
          product
          active
          expiresAt
          createdAt
          updatedAt
        }
        partner_id
        authorized
        partner_name
        external_link
        related_career
        R
        I
        A
        S
        E
        C
        BW0
        BW1
        BW2
        BW3
        is_cluster
        is_active
        app_settings {
          components
          challenges
          custom_apply_mail_template
          usedAlgorithm
          hello_text
          custom_data_policy
        }
        career_cluster_url
        forced_dimension
        career_analyse
        createdAt
        updatedAt
      }
      partner_id
      partner {
        id
        name
        accesses {
          nextToken
        }
        display_name
        authorized
        career_site_url
        user_type
        restricted
        lead_id
        payment_type
        external_payment_meta {
          external_payment_user_id
          external_payment_service
        }
        planned_new_hires_per_year
        app_settings {
          components
          challenges
          custom_apply_mail_template
          usedAlgorithm
          hello_text
          custom_data_policy
        }
        logo
        logo_ratio
        logo_alt
        logo_alt_ratio
        createdAt
        updatedAt
      }
      user {
        id
        owner
        time_in_app
        type
        role
        invited_from
        authorized
        employee_at_partner
        partner {
          id
          name
          display_name
          authorized
          career_site_url
          user_type
          restricted
          lead_id
          payment_type
          planned_new_hires_per_year
          logo
          logo_ratio
          logo_alt
          logo_alt_ratio
          createdAt
          updatedAt
        }
        person {
          id
          owner
          authorized
          invitation_firstname
          firstname
          invitation_lastname
          lastname
          invitation_email
          email
          createdAt
          updatedAt
          personUserId
          personSpaceId
        }
        opens_app
        opens_app_daily
        avg_challenge_rating
        gender
        content_categories
        push_career
        push_jobs
        push_companies
        push_exams
        push_events
        active_space_id
        delete_text
        privacy
        mail_stored
        phone_stored
        last_seen
        createdAt
        updatedAt
        career_right_swipe_rate
        suggested_hero
        hero
        location {
          lat
          lon
          city
        }
        percentiles {
          BIG_FIVE_O_score
          BIG_FIVE_C_score
          BIG_FIVE_o_score
          BIG_FIVE_c_score
          BIG_FIVE_e_score
          BIG_FIVE_a_score
          BIG_FIVE_n_score
          BIG_FIVE_elevation
          RIASEC_IMAGES_elevation
          RIASEC_IMAGES_r_score
          RIASEC_IMAGES_i_score
          RIASEC_IMAGES_a_score
          RIASEC_IMAGES_s_score
          RIASEC_IMAGES_e_score
          RIASEC_IMAGES_c_score
          HEROS_JOURNEY_score_coopcomp
          HEROS_JOURNEY_score_orderchaos
          TRUST_score
          TROLLEY_score
          SPEEDACC_score
          FLYINGDUTCH_score
          RESPDECISION_score
          PATH_FINDER_score
          FLANKER_score
          EMOTIONS_GAME_score
          ANALOGICAL_REASONING_score
          TOWER_OF_LONDON_score
          DIGIT_SPAN_score
          NBACK_score
          FLANKER_avg_speed
        }
        reach_requests {
          nextToken
        }
        reach_settings {
          searching_type
          searching_distance
        }
        push_token
        pinpoint_endpoint
        education_level
        work_experience
        remote
      }
      createdAt
      updatedAt
    }
  }
`;
export const createBug = /* GraphQL */ `
  mutation CreateBug(
    $input: CreateBugInput!
    $condition: ModelBugConditionInput
  ) {
    createBug(input: $input, condition: $condition) {
      owner
      assigned_to
      os
      category
      status
      screenshots
      description
      createdAt
      updatedAt
      id
    }
  }
`;
export const updateBug = /* GraphQL */ `
  mutation UpdateBug(
    $input: UpdateBugInput!
    $condition: ModelBugConditionInput
  ) {
    updateBug(input: $input, condition: $condition) {
      owner
      assigned_to
      os
      category
      status
      screenshots
      description
      createdAt
      updatedAt
      id
    }
  }
`;
export const deleteBug = /* GraphQL */ `
  mutation DeleteBug(
    $input: DeleteBugInput!
    $condition: ModelBugConditionInput
  ) {
    deleteBug(input: $input, condition: $condition) {
      owner
      assigned_to
      os
      category
      status
      screenshots
      description
      createdAt
      updatedAt
      id
    }
  }
`;
export const createAnswer = /* GraphQL */ `
  mutation CreateAnswer(
    $input: CreateAnswerInput!
    $condition: ModelAnswerConditionInput
  ) {
    createAnswer(input: $input, condition: $condition) {
      id
      owner
      exam_id
      config {
        id
        answers {
          id
          owner
          exam_id
          cycle
          user_input
          aivy_output
          rating
          rating_info
          result_rating_info
          score
          version
          attempts
          space_id
          os
          createdAt
          updatedAt
        }
        item_count
        zscore_mean
        zscore_deviation
        heighest_score_answer
        version
        backgroundColor
        title
        timeToComplete
        hasTestRounds
        cardIcon
        cardIconWidth
        cardIconHeight
        image
        createdAt
        updatedAt
      }
      cycle
      user_input
      aivy_output
      rating
      rating_info
      result_rating_info
      score
      version
      attempts
      space_id
      os
      createdAt
      updatedAt
    }
  }
`;
export const updateAnswer = /* GraphQL */ `
  mutation UpdateAnswer(
    $input: UpdateAnswerInput!
    $condition: ModelAnswerConditionInput
  ) {
    updateAnswer(input: $input, condition: $condition) {
      id
      owner
      exam_id
      config {
        id
        answers {
          id
          owner
          exam_id
          cycle
          user_input
          aivy_output
          rating
          rating_info
          result_rating_info
          score
          version
          attempts
          space_id
          os
          createdAt
          updatedAt
        }
        item_count
        zscore_mean
        zscore_deviation
        heighest_score_answer
        version
        backgroundColor
        title
        timeToComplete
        hasTestRounds
        cardIcon
        cardIconWidth
        cardIconHeight
        image
        createdAt
        updatedAt
      }
      cycle
      user_input
      aivy_output
      rating
      rating_info
      result_rating_info
      score
      version
      attempts
      space_id
      os
      createdAt
      updatedAt
    }
  }
`;
export const deleteAnswer = /* GraphQL */ `
  mutation DeleteAnswer(
    $input: DeleteAnswerInput!
    $condition: ModelAnswerConditionInput
  ) {
    deleteAnswer(input: $input, condition: $condition) {
      id
      owner
      exam_id
      config {
        id
        answers {
          id
          owner
          exam_id
          cycle
          user_input
          aivy_output
          rating
          rating_info
          result_rating_info
          score
          version
          attempts
          space_id
          os
          createdAt
          updatedAt
        }
        item_count
        zscore_mean
        zscore_deviation
        heighest_score_answer
        version
        backgroundColor
        title
        timeToComplete
        hasTestRounds
        cardIcon
        cardIconWidth
        cardIconHeight
        image
        createdAt
        updatedAt
      }
      cycle
      user_input
      aivy_output
      rating
      rating_info
      result_rating_info
      score
      version
      attempts
      space_id
      os
      createdAt
      updatedAt
    }
  }
`;
export const createExamConfig = /* GraphQL */ `
  mutation CreateExamConfig(
    $input: CreateExamConfigInput!
    $condition: ModelExamConfigConditionInput
  ) {
    createExamConfig(input: $input, condition: $condition) {
      id
      answers {
        id
        owner
        exam_id
        config {
          id
          item_count
          zscore_mean
          zscore_deviation
          heighest_score_answer
          version
          backgroundColor
          title
          timeToComplete
          hasTestRounds
          cardIcon
          cardIconWidth
          cardIconHeight
          image
          createdAt
          updatedAt
        }
        cycle
        user_input
        aivy_output
        rating
        rating_info
        result_rating_info
        score
        version
        attempts
        space_id
        os
        createdAt
        updatedAt
      }
      item_count
      zscore_mean
      zscore_deviation
      heighest_score_answer
      version
      backgroundColor
      title
      timeToComplete
      hasTestRounds
      cardIcon
      cardIconWidth
      cardIconHeight
      image
      createdAt
      updatedAt
    }
  }
`;
export const updateExamConfig = /* GraphQL */ `
  mutation UpdateExamConfig(
    $input: UpdateExamConfigInput!
    $condition: ModelExamConfigConditionInput
  ) {
    updateExamConfig(input: $input, condition: $condition) {
      id
      answers {
        id
        owner
        exam_id
        config {
          id
          item_count
          zscore_mean
          zscore_deviation
          heighest_score_answer
          version
          backgroundColor
          title
          timeToComplete
          hasTestRounds
          cardIcon
          cardIconWidth
          cardIconHeight
          image
          createdAt
          updatedAt
        }
        cycle
        user_input
        aivy_output
        rating
        rating_info
        result_rating_info
        score
        version
        attempts
        space_id
        os
        createdAt
        updatedAt
      }
      item_count
      zscore_mean
      zscore_deviation
      heighest_score_answer
      version
      backgroundColor
      title
      timeToComplete
      hasTestRounds
      cardIcon
      cardIconWidth
      cardIconHeight
      image
      createdAt
      updatedAt
    }
  }
`;
export const deleteExamConfig = /* GraphQL */ `
  mutation DeleteExamConfig(
    $input: DeleteExamConfigInput!
    $condition: ModelExamConfigConditionInput
  ) {
    deleteExamConfig(input: $input, condition: $condition) {
      id
      answers {
        id
        owner
        exam_id
        config {
          id
          item_count
          zscore_mean
          zscore_deviation
          heighest_score_answer
          version
          backgroundColor
          title
          timeToComplete
          hasTestRounds
          cardIcon
          cardIconWidth
          cardIconHeight
          image
          createdAt
          updatedAt
        }
        cycle
        user_input
        aivy_output
        rating
        rating_info
        result_rating_info
        score
        version
        attempts
        space_id
        os
        createdAt
        updatedAt
      }
      item_count
      zscore_mean
      zscore_deviation
      heighest_score_answer
      version
      backgroundColor
      title
      timeToComplete
      hasTestRounds
      cardIcon
      cardIconWidth
      cardIconHeight
      image
      createdAt
      updatedAt
    }
  }
`;
export const createCareerAnalyse = /* GraphQL */ `
  mutation CreateCareerAnalyse(
    $input: CreateCareerAnalyseInput!
    $condition: ModelCareerAnalyseConditionInput
  ) {
    createCareerAnalyse(input: $input, condition: $condition) {
      id
      input
      verified
      status
      career_id
      partner_id
      authorized
      person_id
      person {
        id
        owner
        authorized
        invitation_firstname
        firstname
        invitation_lastname
        lastname
        invitation_email
        email
        user {
          id
          owner
          time_in_app
          type
          role
          invited_from
          authorized
          employee_at_partner
          opens_app
          opens_app_daily
          avg_challenge_rating
          gender
          content_categories
          push_career
          push_jobs
          push_companies
          push_exams
          push_events
          active_space_id
          delete_text
          privacy
          mail_stored
          phone_stored
          last_seen
          createdAt
          updatedAt
          career_right_swipe_rate
          suggested_hero
          hero
          push_token
          pinpoint_endpoint
          education_level
          work_experience
          remote
        }
        space {
          id
          owner
          newsletter
          progress
          partner_id
          authorized
          career_id
          partner_user_id
          language
          ref_link
          matching_score_to_space_career
          related_spaces
          invitation_id
          callback_url
          expiresAt
          created_with_service
          invitation_reminder_at
          createdAt
          updatedAt
        }
        createdAt
        updatedAt
        personUserId
        personSpaceId
      }
      url_params
      createdAt
      updatedAt
    }
  }
`;
export const updateCareerAnalyse = /* GraphQL */ `
  mutation UpdateCareerAnalyse(
    $input: UpdateCareerAnalyseInput!
    $condition: ModelCareerAnalyseConditionInput
  ) {
    updateCareerAnalyse(input: $input, condition: $condition) {
      id
      input
      verified
      status
      career_id
      partner_id
      authorized
      person_id
      person {
        id
        owner
        authorized
        invitation_firstname
        firstname
        invitation_lastname
        lastname
        invitation_email
        email
        user {
          id
          owner
          time_in_app
          type
          role
          invited_from
          authorized
          employee_at_partner
          opens_app
          opens_app_daily
          avg_challenge_rating
          gender
          content_categories
          push_career
          push_jobs
          push_companies
          push_exams
          push_events
          active_space_id
          delete_text
          privacy
          mail_stored
          phone_stored
          last_seen
          createdAt
          updatedAt
          career_right_swipe_rate
          suggested_hero
          hero
          push_token
          pinpoint_endpoint
          education_level
          work_experience
          remote
        }
        space {
          id
          owner
          newsletter
          progress
          partner_id
          authorized
          career_id
          partner_user_id
          language
          ref_link
          matching_score_to_space_career
          related_spaces
          invitation_id
          callback_url
          expiresAt
          created_with_service
          invitation_reminder_at
          createdAt
          updatedAt
        }
        createdAt
        updatedAt
        personUserId
        personSpaceId
      }
      url_params
      createdAt
      updatedAt
    }
  }
`;
export const deleteCareerAnalyse = /* GraphQL */ `
  mutation DeleteCareerAnalyse(
    $input: DeleteCareerAnalyseInput!
    $condition: ModelCareerAnalyseConditionInput
  ) {
    deleteCareerAnalyse(input: $input, condition: $condition) {
      id
      input
      verified
      status
      career_id
      partner_id
      authorized
      person_id
      person {
        id
        owner
        authorized
        invitation_firstname
        firstname
        invitation_lastname
        lastname
        invitation_email
        email
        user {
          id
          owner
          time_in_app
          type
          role
          invited_from
          authorized
          employee_at_partner
          opens_app
          opens_app_daily
          avg_challenge_rating
          gender
          content_categories
          push_career
          push_jobs
          push_companies
          push_exams
          push_events
          active_space_id
          delete_text
          privacy
          mail_stored
          phone_stored
          last_seen
          createdAt
          updatedAt
          career_right_swipe_rate
          suggested_hero
          hero
          push_token
          pinpoint_endpoint
          education_level
          work_experience
          remote
        }
        space {
          id
          owner
          newsletter
          progress
          partner_id
          authorized
          career_id
          partner_user_id
          language
          ref_link
          matching_score_to_space_career
          related_spaces
          invitation_id
          callback_url
          expiresAt
          created_with_service
          invitation_reminder_at
          createdAt
          updatedAt
        }
        createdAt
        updatedAt
        personUserId
        personSpaceId
      }
      url_params
      createdAt
      updatedAt
    }
  }
`;
export const createApiKey = /* GraphQL */ `
  mutation CreateApiKey(
    $input: CreateApiKeyInput!
    $condition: ModelApiKeyConditionInput
  ) {
    createApiKey(input: $input, condition: $condition) {
      id
      owner
      partner_id
      service
      auth_token
      api_call_count
      createdAt
      updatedAt
    }
  }
`;
export const updateApiKey = /* GraphQL */ `
  mutation UpdateApiKey(
    $input: UpdateApiKeyInput!
    $condition: ModelApiKeyConditionInput
  ) {
    updateApiKey(input: $input, condition: $condition) {
      id
      owner
      partner_id
      service
      auth_token
      api_call_count
      createdAt
      updatedAt
    }
  }
`;
export const deleteApiKey = /* GraphQL */ `
  mutation DeleteApiKey(
    $input: DeleteApiKeyInput!
    $condition: ModelApiKeyConditionInput
  ) {
    deleteApiKey(input: $input, condition: $condition) {
      id
      owner
      partner_id
      service
      auth_token
      api_call_count
      createdAt
      updatedAt
    }
  }
`;
export const createCareer = /* GraphQL */ `
  mutation CreateCareer(
    $input: CreateCareerInput!
    $condition: ModelCareerConditionInput
  ) {
    createCareer(input: $input, condition: $condition) {
      id
      name
      photo_url
      title
      status
      archived
      history {
        eventName
        before
        after
        createdAt
        message
        eventFiredBy
      }
      category
      use_in {
        product
        active
        expiresAt
        createdAt
        updatedAt
      }
      partner_id
      authorized
      partner_name
      external_link
      related_career
      R
      I
      A
      S
      E
      C
      BW0
      BW1
      BW2
      BW3
      is_cluster
      is_active
      app_settings {
        components
        challenges
        custom_apply_mail_template
        custom_invite_talent_mail_templates {
          subject
          text
          id
          createdAt
          updatedAt
          standard
        }
        usedAlgorithm
        hello_text
        custom_data_policy
        notifications {
          webhook
          mail
        }
      }
      career_cluster_url
      forced_dimension
      career_analyse
      createdAt
      updatedAt
    }
  }
`;
export const updateCareer = /* GraphQL */ `
  mutation UpdateCareer(
    $input: UpdateCareerInput!
    $condition: ModelCareerConditionInput
  ) {
    updateCareer(input: $input, condition: $condition) {
      id
      name
      photo_url
      title
      status
      archived
      history {
        eventName
        before
        after
        createdAt
        message
        eventFiredBy
      }
      category
      use_in {
        product
        active
        expiresAt
        createdAt
        updatedAt
      }
      partner_id
      authorized
      partner_name
      external_link
      related_career
      R
      I
      A
      S
      E
      C
      BW0
      BW1
      BW2
      BW3
      is_cluster
      is_active
      app_settings {
        components
        challenges
        custom_apply_mail_template
        custom_invite_talent_mail_templates {
          subject
          text
          id
          createdAt
          updatedAt
          standard
        }
        usedAlgorithm
        hello_text
        custom_data_policy
        notifications {
          webhook
          mail
        }
      }
      career_cluster_url
      forced_dimension
      career_analyse
      createdAt
      updatedAt
    }
  }
`;
export const deleteCareer = /* GraphQL */ `
  mutation DeleteCareer(
    $input: DeleteCareerInput!
    $condition: ModelCareerConditionInput
  ) {
    deleteCareer(input: $input, condition: $condition) {
      id
      name
      photo_url
      title
      status
      archived
      history {
        eventName
        before
        after
        createdAt
        message
        eventFiredBy
      }
      category
      use_in {
        product
        active
        expiresAt
        createdAt
        updatedAt
      }
      partner_id
      authorized
      partner_name
      external_link
      related_career
      R
      I
      A
      S
      E
      C
      BW0
      BW1
      BW2
      BW3
      is_cluster
      is_active
      app_settings {
        components
        challenges
        custom_apply_mail_template
        custom_invite_talent_mail_templates {
          subject
          text
          id
          createdAt
          updatedAt
          standard
        }
        usedAlgorithm
        hello_text
        custom_data_policy
        notifications {
          webhook
          mail
        }
      }
      career_cluster_url
      forced_dimension
      career_analyse
      createdAt
      updatedAt
    }
  }
`;
export const createEvent = /* GraphQL */ `
  mutation CreateEvent(
    $input: CreateEventInput!
    $condition: ModelEventConditionInput
  ) {
    createEvent(input: $input, condition: $condition) {
      id
      title
      location {
        lat
        lon
        city
      }
      city
      postalcode
      adress
      location_name
      referlink
      price
      hashtags
      price_subtitle
      thumb
      description
      start_date
      end_date
      start_time
      end_time
      time_infos
      facebook
      twitter
      instagram
      youtube
      images
      categories
      createdAt
      updatedAt
    }
  }
`;
export const updateEvent = /* GraphQL */ `
  mutation UpdateEvent(
    $input: UpdateEventInput!
    $condition: ModelEventConditionInput
  ) {
    updateEvent(input: $input, condition: $condition) {
      id
      title
      location {
        lat
        lon
        city
      }
      city
      postalcode
      adress
      location_name
      referlink
      price
      hashtags
      price_subtitle
      thumb
      description
      start_date
      end_date
      start_time
      end_time
      time_infos
      facebook
      twitter
      instagram
      youtube
      images
      categories
      createdAt
      updatedAt
    }
  }
`;
export const deleteEvent = /* GraphQL */ `
  mutation DeleteEvent(
    $input: DeleteEventInput!
    $condition: ModelEventConditionInput
  ) {
    deleteEvent(input: $input, condition: $condition) {
      id
      title
      location {
        lat
        lon
        city
      }
      city
      postalcode
      adress
      location_name
      referlink
      price
      hashtags
      price_subtitle
      thumb
      description
      start_date
      end_date
      start_time
      end_time
      time_infos
      facebook
      twitter
      instagram
      youtube
      images
      categories
      createdAt
      updatedAt
    }
  }
`;
export const createEventOrganizer = /* GraphQL */ `
  mutation CreateEventOrganizer(
    $input: CreateEventOrganizerInput!
    $condition: ModelEventOrganizerConditionInput
  ) {
    createEventOrganizer(input: $input, condition: $condition) {
      id
      name
      logo
      adress
      website
      phone
      mail
      is_partner
      createdAt
      updatedAt
    }
  }
`;
export const updateEventOrganizer = /* GraphQL */ `
  mutation UpdateEventOrganizer(
    $input: UpdateEventOrganizerInput!
    $condition: ModelEventOrganizerConditionInput
  ) {
    updateEventOrganizer(input: $input, condition: $condition) {
      id
      name
      logo
      adress
      website
      phone
      mail
      is_partner
      createdAt
      updatedAt
    }
  }
`;
export const deleteEventOrganizer = /* GraphQL */ `
  mutation DeleteEventOrganizer(
    $input: DeleteEventOrganizerInput!
    $condition: ModelEventOrganizerConditionInput
  ) {
    deleteEventOrganizer(input: $input, condition: $condition) {
      id
      name
      logo
      adress
      website
      phone
      mail
      is_partner
      createdAt
      updatedAt
    }
  }
`;
export const createArticle = /* GraphQL */ `
  mutation CreateArticle(
    $input: CreateArticleInput!
    $condition: ModelArticleConditionInput
  ) {
    createArticle(input: $input, condition: $condition) {
      id
      title
      thumb
      subtitle
      images
      categories
      content
      createdAt
      updatedAt
    }
  }
`;
export const updateArticle = /* GraphQL */ `
  mutation UpdateArticle(
    $input: UpdateArticleInput!
    $condition: ModelArticleConditionInput
  ) {
    updateArticle(input: $input, condition: $condition) {
      id
      title
      thumb
      subtitle
      images
      categories
      content
      createdAt
      updatedAt
    }
  }
`;
export const deleteArticle = /* GraphQL */ `
  mutation DeleteArticle(
    $input: DeleteArticleInput!
    $condition: ModelArticleConditionInput
  ) {
    deleteArticle(input: $input, condition: $condition) {
      id
      title
      thumb
      subtitle
      images
      categories
      content
      createdAt
      updatedAt
    }
  }
`;
export const createJob = /* GraphQL */ `
  mutation CreateJob(
    $input: CreateJobInput!
    $condition: ModelJobConditionInput
  ) {
    createJob(input: $input, condition: $condition) {
      id
      company_id
      company_logo
      company_name
      affiliation_to_careers
      address
      title
      description
      applicationinfo {
        emails
        instruction
        uris
      }
      partner
      job_image
      extern_offer
      degree_types
      employment_type
      language_code
      job_start_time
      job_end_time
      job_publish_time
      job_expire_time
      ttl
      job_level
      promotion_value
      qualifications
      location {
        lat
        lon
        city
      }
      createdAt
      updatedAt
    }
  }
`;
export const updateJob = /* GraphQL */ `
  mutation UpdateJob(
    $input: UpdateJobInput!
    $condition: ModelJobConditionInput
  ) {
    updateJob(input: $input, condition: $condition) {
      id
      company_id
      company_logo
      company_name
      affiliation_to_careers
      address
      title
      description
      applicationinfo {
        emails
        instruction
        uris
      }
      partner
      job_image
      extern_offer
      degree_types
      employment_type
      language_code
      job_start_time
      job_end_time
      job_publish_time
      job_expire_time
      ttl
      job_level
      promotion_value
      qualifications
      location {
        lat
        lon
        city
      }
      createdAt
      updatedAt
    }
  }
`;
export const deleteJob = /* GraphQL */ `
  mutation DeleteJob(
    $input: DeleteJobInput!
    $condition: ModelJobConditionInput
  ) {
    deleteJob(input: $input, condition: $condition) {
      id
      company_id
      company_logo
      company_name
      affiliation_to_careers
      address
      title
      description
      applicationinfo {
        emails
        instruction
        uris
      }
      partner
      job_image
      extern_offer
      degree_types
      employment_type
      language_code
      job_start_time
      job_end_time
      job_publish_time
      job_expire_time
      ttl
      job_level
      promotion_value
      qualifications
      location {
        lat
        lon
        city
      }
      createdAt
      updatedAt
    }
  }
`;
export const createApplicationInteract = /* GraphQL */ `
  mutation CreateApplicationInteract(
    $input: CreateApplicationInteractInput!
    $condition: ModelApplicationInteractConditionInput
  ) {
    createApplicationInteract(input: $input, condition: $condition) {
      id
      owner
      owner_name
      company_logo
      company_id
      application_status
      address
      title
      description
      applicationinfo {
        emails
        instruction
        uris
      }
      partner
      job_image
      extern_offer
      degree_types
      employment_type
      language_code
      job_start_time
      job_end_time
      job_publish_time
      job_expire_time
      job_level
      promotion_value
      qualifications
      location {
        lat
        lon
        city
      }
      createdAt
      updatedAt
    }
  }
`;
export const updateApplicationInteract = /* GraphQL */ `
  mutation UpdateApplicationInteract(
    $input: UpdateApplicationInteractInput!
    $condition: ModelApplicationInteractConditionInput
  ) {
    updateApplicationInteract(input: $input, condition: $condition) {
      id
      owner
      owner_name
      company_logo
      company_id
      application_status
      address
      title
      description
      applicationinfo {
        emails
        instruction
        uris
      }
      partner
      job_image
      extern_offer
      degree_types
      employment_type
      language_code
      job_start_time
      job_end_time
      job_publish_time
      job_expire_time
      job_level
      promotion_value
      qualifications
      location {
        lat
        lon
        city
      }
      createdAt
      updatedAt
    }
  }
`;
export const deleteApplicationInteract = /* GraphQL */ `
  mutation DeleteApplicationInteract(
    $input: DeleteApplicationInteractInput!
    $condition: ModelApplicationInteractConditionInput
  ) {
    deleteApplicationInteract(input: $input, condition: $condition) {
      id
      owner
      owner_name
      company_logo
      company_id
      application_status
      address
      title
      description
      applicationinfo {
        emails
        instruction
        uris
      }
      partner
      job_image
      extern_offer
      degree_types
      employment_type
      language_code
      job_start_time
      job_end_time
      job_publish_time
      job_expire_time
      job_level
      promotion_value
      qualifications
      location {
        lat
        lon
        city
      }
      createdAt
      updatedAt
    }
  }
`;
export const createPartner = /* GraphQL */ `
  mutation CreatePartner(
    $input: CreatePartnerInput!
    $condition: ModelPartnerConditionInput
  ) {
    createPartner(input: $input, condition: $condition) {
      id
      name
      accesses {
        items {
          id
          owner
          time_in_app
          type
          role
          invited_from
          authorized
          employee_at_partner
          opens_app
          opens_app_daily
          avg_challenge_rating
          gender
          content_categories
          push_career
          push_jobs
          push_companies
          push_exams
          push_events
          active_space_id
          delete_text
          privacy
          mail_stored
          phone_stored
          last_seen
          createdAt
          updatedAt
          career_right_swipe_rate
          suggested_hero
          hero
          push_token
          pinpoint_endpoint
          education_level
          work_experience
          remote
        }
        nextToken
      }
      display_name
      authorized
      career_site_url
      user_type
      restricted
      lead_id
      payment_type
      external_payment_meta {
        external_payment_user_id
        external_payment_service
      }
      planned_new_hires_per_year
      app_settings {
        components
        challenges
        custom_apply_mail_template
        custom_invite_talent_mail_templates {
          subject
          text
          id
          createdAt
          updatedAt
          standard
        }
        usedAlgorithm
        hello_text
        custom_data_policy
        notifications {
          webhook
          mail
        }
      }
      logo
      logo_ratio
      logo_alt
      logo_alt_ratio
      createdAt
      updatedAt
    }
  }
`;
export const updatePartner = /* GraphQL */ `
  mutation UpdatePartner(
    $input: UpdatePartnerInput!
    $condition: ModelPartnerConditionInput
  ) {
    updatePartner(input: $input, condition: $condition) {
      id
      name
      accesses {
        items {
          id
          owner
          time_in_app
          type
          role
          invited_from
          authorized
          employee_at_partner
          opens_app
          opens_app_daily
          avg_challenge_rating
          gender
          content_categories
          push_career
          push_jobs
          push_companies
          push_exams
          push_events
          active_space_id
          delete_text
          privacy
          mail_stored
          phone_stored
          last_seen
          createdAt
          updatedAt
          career_right_swipe_rate
          suggested_hero
          hero
          push_token
          pinpoint_endpoint
          education_level
          work_experience
          remote
        }
        nextToken
      }
      display_name
      authorized
      career_site_url
      user_type
      restricted
      lead_id
      payment_type
      external_payment_meta {
        external_payment_user_id
        external_payment_service
      }
      planned_new_hires_per_year
      app_settings {
        components
        challenges
        custom_apply_mail_template
        custom_invite_talent_mail_templates {
          subject
          text
          id
          createdAt
          updatedAt
          standard
        }
        usedAlgorithm
        hello_text
        custom_data_policy
        notifications {
          webhook
          mail
        }
      }
      logo
      logo_ratio
      logo_alt
      logo_alt_ratio
      createdAt
      updatedAt
    }
  }
`;
export const deletePartner = /* GraphQL */ `
  mutation DeletePartner(
    $input: DeletePartnerInput!
    $condition: ModelPartnerConditionInput
  ) {
    deletePartner(input: $input, condition: $condition) {
      id
      name
      accesses {
        items {
          id
          owner
          time_in_app
          type
          role
          invited_from
          authorized
          employee_at_partner
          opens_app
          opens_app_daily
          avg_challenge_rating
          gender
          content_categories
          push_career
          push_jobs
          push_companies
          push_exams
          push_events
          active_space_id
          delete_text
          privacy
          mail_stored
          phone_stored
          last_seen
          createdAt
          updatedAt
          career_right_swipe_rate
          suggested_hero
          hero
          push_token
          pinpoint_endpoint
          education_level
          work_experience
          remote
        }
        nextToken
      }
      display_name
      authorized
      career_site_url
      user_type
      restricted
      lead_id
      payment_type
      external_payment_meta {
        external_payment_user_id
        external_payment_service
      }
      planned_new_hires_per_year
      app_settings {
        components
        challenges
        custom_apply_mail_template
        custom_invite_talent_mail_templates {
          subject
          text
          id
          createdAt
          updatedAt
          standard
        }
        usedAlgorithm
        hello_text
        custom_data_policy
        notifications {
          webhook
          mail
        }
      }
      logo
      logo_ratio
      logo_alt
      logo_alt_ratio
      createdAt
      updatedAt
    }
  }
`;
export const createService = /* GraphQL */ `
  mutation CreateService(
    $input: CreateServiceInput!
    $condition: ModelServiceConditionInput
  ) {
    createService(input: $input, condition: $condition) {
      id
      type
      name
      token
      createdAt
      updatedAt
    }
  }
`;
export const updateService = /* GraphQL */ `
  mutation UpdateService(
    $input: UpdateServiceInput!
    $condition: ModelServiceConditionInput
  ) {
    updateService(input: $input, condition: $condition) {
      id
      type
      name
      token
      createdAt
      updatedAt
    }
  }
`;
export const deleteService = /* GraphQL */ `
  mutation DeleteService(
    $input: DeleteServiceInput!
    $condition: ModelServiceConditionInput
  ) {
    deleteService(input: $input, condition: $condition) {
      id
      type
      name
      token
      createdAt
      updatedAt
    }
  }
`;
export const createCompany = /* GraphQL */ `
  mutation CreateCompany(
    $input: CreateCompanyInput!
    $condition: ModelCompanyConditionInput
  ) {
    createCompany(input: $input, condition: $condition) {
      id
      headerimage
      google_id
      founding_year
      instagram
      youtube
      twitter
      adress
      images
      postalcode
      description
      city
      company_size
      location {
        lat
        lon
        city
      }
      website_uri
      career_site_uri
      sector
      logo
      name
      partner
      createdAt
      updatedAt
    }
  }
`;
export const updateCompany = /* GraphQL */ `
  mutation UpdateCompany(
    $input: UpdateCompanyInput!
    $condition: ModelCompanyConditionInput
  ) {
    updateCompany(input: $input, condition: $condition) {
      id
      headerimage
      google_id
      founding_year
      instagram
      youtube
      twitter
      adress
      images
      postalcode
      description
      city
      company_size
      location {
        lat
        lon
        city
      }
      website_uri
      career_site_uri
      sector
      logo
      name
      partner
      createdAt
      updatedAt
    }
  }
`;
export const deleteCompany = /* GraphQL */ `
  mutation DeleteCompany(
    $input: DeleteCompanyInput!
    $condition: ModelCompanyConditionInput
  ) {
    deleteCompany(input: $input, condition: $condition) {
      id
      headerimage
      google_id
      founding_year
      instagram
      youtube
      twitter
      adress
      images
      postalcode
      description
      city
      company_size
      location {
        lat
        lon
        city
      }
      website_uri
      career_site_uri
      sector
      logo
      name
      partner
      createdAt
      updatedAt
    }
  }
`;
export const createCompanyinteract = /* GraphQL */ `
  mutation CreateCompanyinteract(
    $input: CreateCompanyinteractInput!
    $condition: ModelCompanyinteractConditionInput
  ) {
    createCompanyinteract(input: $input, condition: $condition) {
      id
      decision
      owner
      company_id
      createdAt
      updatedAt
    }
  }
`;
export const updateCompanyinteract = /* GraphQL */ `
  mutation UpdateCompanyinteract(
    $input: UpdateCompanyinteractInput!
    $condition: ModelCompanyinteractConditionInput
  ) {
    updateCompanyinteract(input: $input, condition: $condition) {
      id
      decision
      owner
      company_id
      createdAt
      updatedAt
    }
  }
`;
export const deleteCompanyinteract = /* GraphQL */ `
  mutation DeleteCompanyinteract(
    $input: DeleteCompanyinteractInput!
    $condition: ModelCompanyinteractConditionInput
  ) {
    deleteCompanyinteract(input: $input, condition: $condition) {
      id
      decision
      owner
      company_id
      createdAt
      updatedAt
    }
  }
`;
export const createCareerinteract = /* GraphQL */ `
  mutation CreateCareerinteract(
    $input: CreateCareerinteractInput!
    $condition: ModelCareerinteractConditionInput
  ) {
    createCareerinteract(input: $input, condition: $condition) {
      id
      owner
      career_id
      decision
      randomCard
      score
      createdAt
      updatedAt
    }
  }
`;
export const updateCareerinteract = /* GraphQL */ `
  mutation UpdateCareerinteract(
    $input: UpdateCareerinteractInput!
    $condition: ModelCareerinteractConditionInput
  ) {
    updateCareerinteract(input: $input, condition: $condition) {
      id
      owner
      career_id
      decision
      randomCard
      score
      createdAt
      updatedAt
    }
  }
`;
export const deleteCareerinteract = /* GraphQL */ `
  mutation DeleteCareerinteract(
    $input: DeleteCareerinteractInput!
    $condition: ModelCareerinteractConditionInput
  ) {
    deleteCareerinteract(input: $input, condition: $condition) {
      id
      owner
      career_id
      decision
      randomCard
      score
      createdAt
      updatedAt
    }
  }
`;
