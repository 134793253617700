import { CheckIcon } from "@heroicons/react/outline";
import { ArrowSmDownIcon, ArrowSmUpIcon } from "@heroicons/react/solid";
import { API, graphqlOperation } from "aws-amplify";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import * as queries from "../graphql/queries";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

export default function Unsubscribe() {
  const { id } = useParams();
  const [unsubscribed, setUnsubscribed] = useState(false);

  async function unsubscribeMe() {
    try {
      const res = await API.graphql({
        query: queries.pinpointControl,
        variables: { user_id: id, action: "unsubscribeEndpoint" },
        authMode: "AWS_IAM",
      });
      setUnsubscribed(true);
      console.log(JSON.parse(res.data.pinpointControl));
    } catch (error) {
      error.errors.map((error) => {
        console.log(error);
        alert(error.message);
      });
    }
  }

  return (
    <div className="min-h-full h-screen pt-16 pb-12 flex flex-col bg-white">
      <main className="flex-grow flex flex-col justify-center max-w-7xl w-full mx-auto px-4 sm:px-6 lg:px-8">
        <div className="flex-shrink-0 flex justify-center">
          <a href="/" className="inline-flex">
            <img
              className="w-auto h-32"
              src="https://react-native-app-content.s3.eu-central-1.amazonaws.com/assets/Aivy_Logo-Slogan_RGB.jpg"
              alt=""
            />
          </a>
        </div>

        {unsubscribed ? (
          <div className="bg-white">
            <div className="max-w-7xl mx-auto text-center py-12 px-4 sm:px-6 lg:py-16 lg:px-8">
              <h2 className="text-3xl font-extrabold tracking-tight text-gray-900 sm:text-4xl">
                <span className="block">Abgemeldet!</span>
              </h2>

              {/* <div className="mt-8 flex justify-center">
                <div className="inline-flex rounded-md shadow">
                  <a
                    href="https://aivy.app"
                    className="inline-flex items-center justify-center px-5 py-3 border border-transparent text-base font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700"
                  >
                    Rückgängig machen
                  </a>
                </div>
              </div> */}
            </div>
          </div>
        ) : (
          <div className="bg-white">
            <div className="max-w-7xl mx-auto text-center py-12 px-4 sm:px-6 lg:py-16 lg:px-8">
              <h2 className="text-3xl font-extrabold tracking-tight text-gray-900 sm:text-4xl">
                <span className="block">Du meldest dich ab...</span>
              </h2>
              <p className="mt-2 text-base text-gray-500">
                Du erhälst in Zukunft keine Newsletter E-Mails mehr, bist du
                sicher? Wir würden dich gern weiter über coole neue Funktionen
                informieren
              </p>
              <div className="mt-8 flex justify-center">
                <div className="inline-flex rounded-md shadow">
                  <a
                    href="https://aivy.app"
                    className="inline-flex items-center justify-center px-5 py-3 border border-transparent text-base font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700"
                  >
                    Ich höre weiter gespannt zu
                  </a>
                </div>

                <div
                  className="ml-3 inline-flex"
                  onClick={() => unsubscribeMe()}
                >
                  <a className="inline-flex items-center justify-center px-5 py-3 border border-transparent text-base font-medium rounded-md text-indigo-700 bg-indigo-100 hover:bg-indigo-200">
                    Snooze
                  </a>
                </div>
              </div>
            </div>
          </div>
        )}
      </main>
      <footer className="flex-shrink-0 max-w-7xl w-full mx-auto px-4 sm:px-6 lg:px-8">
        <nav className="flex justify-center space-x-4">
          <a
            href="#"
            className="text-sm font-medium text-gray-500 hover:text-gray-600"
          >
            Kontakt
          </a>
          <span
            className="inline-block border-l border-gray-300"
            aria-hidden="true"
          />

          <a
            href="#"
            className="text-sm font-medium text-gray-500 hover:text-gray-600"
          >
            Impressum
          </a>
        </nav>
      </footer>
    </div>
  );
}
