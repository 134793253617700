import "./App.css";
import React, { useEffect, useState, useMemo, useCallback } from "react";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
} from "react-router-dom";
import Amplify from "aws-amplify";
import { useTranslation } from "react-i18next";
import "@aws-amplify/ui-react/styles.css";

import Layout from "./components/layout";
import { Loader, Notification, Modal } from "./components";
import { routes } from "./routes/index";
import { LanguageContext, NotificationContext } from "./context";
import Error404 from "./pages/error-404";
import awsconfig from "./aws-exports";

import NotificationHolder from "./NotificationHolder";
import ModalHolder from "./ModalHolder";
import WindowFocusHandler from "./components/window-focus-handler";
import { localStorage } from "./helpers/local-storage";

Amplify.configure(awsconfig);

const TRANSLATION_LOCATION = "app.";

function App() {
  const { i18n, t } = useTranslation();
  const windowGlobal = typeof window !== "undefined" && window;

  const [currentLang, setCurrentLang] = useState("de");

  useEffect(() => {
    const languageFromStore = localStorage.getItem("language") || "de";

    setCurrentLang(languageFromStore);
    i18n.changeLanguage(languageFromStore);
  }, [i18n]);

  useEffect(() => {
    localStorage.setItem("session-timestamp", Date.now());
  }, []);

  const languageContext = useMemo(
    () => ({
      changeLanguage: (payload) => {
        setCurrentLang(payload);
        windowGlobal.localStorage.setItem("language", payload);
        i18n.changeLanguage(payload.toLowerCase());
      },
      getLanguage: () => {
        return currentLang;
      },
    }),
    [currentLang, i18n, windowGlobal.localStorage]
  );

  const success = (message) => {
    NotificationHolder.notification.alertWithType("success", message);
  };

  const error = useCallback(
    (message = t(TRANSLATION_LOCATION + "notification_error_default")) => {
      NotificationHolder.notification.alertWithType("error", message);
    },
    [t]
  );

  const notifications = useMemo(
    () => ({
      success,
      error,
    }),
    [error]
  );

  return (
    <>
      <NotificationContext.Provider value={notifications}>
        <LanguageContext.Provider value={languageContext}>
          <Router basename="/">
            <Switch>
              <Route path="/" exact>
                <Redirect to="/unsubscribe" />
              </Route>
              {routes.map(({ path, Component }) => (
                <Route key={path} path={path}>
                  <Component />
                </Route>
              ))}
              <Route path="*">
                <Error404 />
              </Route>
            </Switch>
          </Router>
        </LanguageContext.Provider>
      </NotificationContext.Provider>
      <Notification ref={(ref) => NotificationHolder.setNotification(ref)} />
      <Modal ref={(ref) => ModalHolder.setModal(ref)} />
      <WindowFocusHandler userProfile={{}} />
    </>
  );
}

export default App;
