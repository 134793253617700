/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "eu-central-1",
    "aws_cognito_identity_pool_id": "eu-central-1:58522abb-6fd6-487c-b58c-f3b2eefec7eb",
    "aws_cognito_region": "eu-central-1",
    "aws_user_pools_id": "eu-central-1_YLHI2OQpd",
    "aws_user_pools_web_client_id": "4kuhmd22f411c8s8f0524fbi9v",
    "oauth": {},
    "aws_cognito_username_attributes": [
        "EMAIL"
    ],
    "aws_cognito_social_providers": [],
    "aws_cognito_signup_attributes": [
        "EMAIL"
    ],
    "aws_cognito_mfa_configuration": "OPTIONAL",
    "aws_cognito_mfa_types": [
        "SMS",
        "TOTP"
    ],
    "aws_cognito_password_protection_settings": {
        "passwordPolicyMinLength": 8,
        "passwordPolicyCharacters": []
    },
    "aws_cognito_verification_mechanisms": [
        "EMAIL"
    ],
    "aws_appsync_graphqlEndpoint": "https://wkrzv6awlzbotipe3bvjtbymcq.appsync-api.eu-central-1.amazonaws.com/graphql",
    "aws_appsync_region": "eu-central-1",
    "aws_appsync_authenticationType": "AMAZON_COGNITO_USER_POOLS",
    "aws_appsync_apiKey": "da2-cpv72tfpf5e7rc7gsh2us6kvlq",
    "aws_cloud_logic_custom": [
        {
            "name": "AdminQueries",
            "endpoint": "https://9ivuwfzgg8.execute-api.eu-central-1.amazonaws.com/production",
            "region": "eu-central-1"
        },
        {
            "name": "restify",
            "endpoint": "https://965cdu48mg.execute-api.eu-central-1.amazonaws.com/production",
            "region": "eu-central-1"
        }
    ],
    "aws_mobile_analytics_app_id": "5a5dd124150f45cd94eab32a403e2391",
    "aws_mobile_analytics_app_region": "eu-central-1",
    "aws_user_files_s3_bucket": "aivy-user-content-production",
    "aws_user_files_s3_bucket_region": "eu-central-1"
};


export default awsmobile;
